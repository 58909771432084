import { IPageStartService } from './../../shared/pageStartService';
import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class UpgradeDaymarkController {
    resourceTypes = new Dictionary();
    resources = [];
    visibleFilteredResources = [];
    visibleFilteredResourcesOtherResourceTypes = [];
    resourcesOtherResourceTypes = [];

    currentResourcePage = 1;
    resourcePageNrs = [];

    currentResourcePageOtherResourceTypes = 1;
    resourceOtherResourceTypesPageNrs = [];

    usePreferencesFilter: boolean;
    disablePreferencesFilter: boolean;
    currentResourceTypeId: number;
    allResourcesSelected = false;

    private commonSvc: any;
    private daymarkActivity: any;
    private firstUpgrade = false;
    private previouslySelectedResources: Array<number> = [];
    private resourcesPerPage = 15;
    private selectedResources: Array<any> = [];
    private selectedResourceIds: Array<number> = [];
    private useResourceFilter: boolean;

    static $inject = [
        "$scope",
        "$stateParams",
        "$state",
        "$timeout",
        "translationService",
        "pageStartService",
        "userService"
    ];
    constructor(
        public $scope: any,
        private $stateParams: any,
        private $state: any,
        private $timeout: any,
        private translationService: ITranslationService,
        private pageStartService: IPageStartService,
        private userService: any
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, "upgradeDaymark");
        this.commonSvc.start(() => { this.onInit(); });
        this.usePreferencesFilter = this.userService.getDisplaySettingSwitch("planboard.applyUnitFilterToResources");
        this.useResourceFilter = this.userService.getDisplaySettingSwitch("planboard.applyResourceFilterInPlanboard");
        if (!this.usePreferencesFilter) {
            this.disablePreferencesFilter = true;
        }
    }

    private onInit() {
        this.commonSvc.loadData("api/Activities/GetActivityGroup/" + this.$stateParams.activityId,
            null,
            (success) => {
                if (success.data.length === 1) {
                    this.daymarkActivity = success.data[0];
                    this.firstUpgrade = true;
                }
                else {
                    this.daymarkActivity = success.data.find(act => act.resourceId === null);
                    this.previouslySelectedResources = success.data.map(act => act.resourceId);
                    this.firstUpgrade = false;
                }
                this.currentResourceTypeId = Planboard.selectedActivity
                    ? Planboard.selectedActivity.resourceTypeId
                    : null;

                // get activity type of daymark
                this.commonSvc.loadData("api/ActivityTypes/" + this.daymarkActivity.activityTypeId,
                    null,
                    (success) => {
                        var allowedResourceTypes = success.data.resourceTypeIdList;

                        // get resource types that are valid for the daymark
                        this.commonSvc.loadData("api/ResourceTypes/ForPlanningBoard",
                            this.resourceTypes,
                            (success) => {
                                this.resourceTypes.forEach((key) => {
                                    if (allowedResourceTypes.indexOf(parseInt(key)) < 0) {
                                        this.resourceTypes.remove(key);
                                    }
                                });
                            }, null, true, true);

                    }, null, true, false);
                this.loadResources();
            },
            null, true, false);
    }

    public onResourceTypeSelected(resourceTypeId) {
        if (this.currentResourceTypeId !== resourceTypeId) {
            this.currentResourceTypeId = resourceTypeId;
            this.setResourcesFromOtherResourceTypes(resourceTypeId);
            this.loadResources();
        }
    }

    public onTogglePreferencesFilter() {
        this.usePreferencesFilter = !this.usePreferencesFilter;
        this.loadResources();
    }

    public onSwitchPage(page: number) {
        this.currentResourcePage = Math.max(Math.min(page, this.resourcePageNrs.length), 1);
        this.setResourcesToShow();
    }

    public onSwitchPageOtherResourceTypes(page: number) {
        this.currentResourcePageOtherResourceTypes = Math.max(Math.min(page, this.resourceOtherResourceTypesPageNrs.length), 1);
        this.setResourcesOtherResourceTypesToShow();
    }

    public onResourceSelected(resource) {
        if (this.selectedResourceIds.indexOf(resource.id) < 0) {
            this.selectedResources.push(resource);
            this.selectedResourceIds.push(resource.id);
        }
        else {
            this.selectedResources = this.selectedResources.filter(r => r.id !== resource.id);
            this.selectedResourceIds = this.selectedResourceIds.filter(id => id !== resource.id);
        }
    }

    public onSelectAll() {
        this.$timeout(() => {
            this.resources.forEach(resource => {
                if (!resource.disabled) {
                    if (this.allResourcesSelected) {
                        if (this.selectedResourceIds.indexOf(resource.id) < 0) {
                            this.selectedResources.push(resource);
                            this.selectedResourceIds.push(resource.id);
                        }
                        resource.selected = true;
                    }
                    else {
                        this.selectedResources = this.selectedResources.filter(r => r.id !== resource.id);
                        this.selectedResourceIds = this.selectedResourceIds.filter(id => id !== resource.id);
                        resource.selected = false;
                    }
                }
            });
        });
    }

    public onSaveSelection() {
        if (this.firstUpgrade && this.selectedResources.length === 1) {
            return;
        }
        var newActivities = [];
        var correctedEndDate = Timezone.correctTimeZoneInfo(Timezone.parseDate(this.daymarkActivity.endDate));
        var correctedStartDate = Timezone.correctTimeZoneInfo(Timezone.parseDate(this.daymarkActivity.startDate));

        this.selectedResources.forEach(resource => {
            var activityResource = {
                activityTypeId: this.daymarkActivity.activityTypeId,
                endDate: correctedEndDate,
                id: -1000,
                memoId: null,
                parentId: this.daymarkActivity.id,
                resourceId: resource.id,
                resourceTypeId: this.daymarkActivity.resourceTypeId,
                startDate: correctedStartDate,
                status: 1,
            };
            newActivities.push(Planboard.prepareActivityForWebApi(activityResource as any));
        });

        this.commonSvc.post("api/Activities/UpgradeDaymark", newActivities,
            this.reloadForPlanboard, this.saveFailedFunction, true);
    }

    private reloadForPlanboard = () => {
        var groupIds = [];

        this.selectedResourceIds.forEach(id => {
            var groupIndex = Planboard.activities.getResourceGroupIndex(id);
            if (groupIds.indexOf(groupIndex) < 0) {
                groupIds.push(groupIndex);
            };
            Planboard.activities.clearCountersForResource(id);
        });

        groupIds.forEach((groupId) => {
            Planboard.activities.resetDaysReceived(null, null, null, groupId, groupId < 0, true, this.selectedResourceIds);
        });

        Planboard.redrawAll();
        Planboard.timedRefresh();
        this.$state.transitionTo("planboard");
    }

    private saveFailedFunction = (error) => {
        var failureReasons = Planboard.getActivityFailureReasons(error && error.data ? error.data : error);

        this.commonSvc.hidePendingOperationInfo();

        if (error.data && error.data.insertedActivities && error.data.insertedActivities.length > 0) {
            // there might be errors, but also succesfully added activities! 
            const messageText = error.data.insertedActivities.length +
                this.$scope.textLabels.UPGRADE_DAYMARK_PART_ERROR_TEXT + failureReasons;
            this.commonSvc.showDialog(this.$scope.textLabels.UPGRADE_DAYMARK_PART_ERROR,
                messageText,
                this.$scope.textLabels.OK,
                this.reloadForPlanboard);
        }
        else {
            this.commonSvc.showDialog(this.$scope.textLabels.UPGRADE_DAYMARK_ERROR,
                failureReasons,
                this.$scope.textLabels.OK,
                null);
        }
    }

    private setResourcesToShow() {
        this.allResourcesSelected = this.resources.every(r => r.selected);

        var currentPaginationIndexStart = (this.currentResourcePage - 1) * this.resourcesPerPage;
        var currentPaginationIndexEnd = currentPaginationIndexStart + this.resourcesPerPage;
        this.visibleFilteredResources = this.resources.slice(currentPaginationIndexStart, currentPaginationIndexEnd);

        this.resourcePageNrs = [];
        var amountOfPages = Math.ceil(this.resources.length / this.resourcesPerPage);
        for (var i = 0; i < amountOfPages; i++) {
            this.resourcePageNrs.push(i + 1);
        }
    }

    private setResourcesOtherResourceTypesToShow() {
        var currentPaginationIndexStart = (this.currentResourcePageOtherResourceTypes - 1) * this.resourcesPerPage;
        var currentPaginationIndexEnd = currentPaginationIndexStart + this.resourcesPerPage;
        this.visibleFilteredResourcesOtherResourceTypes = this.resourcesOtherResourceTypes.slice(currentPaginationIndexStart, currentPaginationIndexEnd);

        this.resourceOtherResourceTypesPageNrs = [];
        var amountOfPages = Math.ceil(this.resourcesOtherResourceTypes.length / this.resourcesPerPage);
        for (var i = 0; i < amountOfPages; i++) {
            this.resourceOtherResourceTypesPageNrs.push(i + 1);
        }
    }

    private setResourcesFromOtherResourceTypes(resourceType: number) {
        this.resourcesOtherResourceTypes = this.resourcesOtherResourceTypes.filter(resource =>
            resource.resourceType !== resourceType
        );

        this.resourcesOtherResourceTypes = this.resourcesOtherResourceTypes.concat(this.resources.filter(resource => resource.selected));
    }

    private loadResources() {
        // The filter and sorting fucntionality should not be used in this update daymark screen.
        // This means sortDescending, applyUnitFilter, applyResourceFilter are always false and
        // sortByResourcePropertyIds is always null.
        var resourceFilterParams =
        {
            resourceTypeId: this.currentResourceTypeId,
            referenceDate: Timezone.rollDateForWebApi(this.daymarkActivity.startDate),
            beforeReferenceDate: Timezone.rollDateForWebApi(this.daymarkActivity.endDate),
            sortByResourcePropertyIds: null,
            sortDescending: false,
            applyUnitFilter: false,
            applyResourceFilter: false
        };

        this.commonSvc.post("api/Resources/ForPlanningBoard/Filtered", resourceFilterParams,
            (success) => {
                this.resources = success.data;
                this.resources.forEach(resource => {
                    resource.resourceType = this.currentResourceTypeId;

                    if (this.firstUpgrade) {
                        if (resource.id === this.daymarkActivity.resourceId) {
                            resource.selected = true;
                            resource.disabled = true;

                            // for first upgrade, we want to add the original resource as well, since it has to be moved down one level in the daymark-activity tree
                            if (this.selectedResourceIds.indexOf(resource.id) < 0) {
                                this.selectedResourceIds.push(resource.id);
                                this.selectedResources.push(resource);
                            }
                        }
                    }
                    else {
                        var previouslySelected = this.previouslySelectedResources.indexOf(resource.id) > -1;
                        resource.selected = previouslySelected;
                        resource.disabled = previouslySelected;
                    }

                    // remove resources that have multiple resource types
                    if (this.resourcesOtherResourceTypes.some(r => r.id === resource.id)) {
                        this.resourcesOtherResourceTypes = this.resourcesOtherResourceTypes.filter(rt => rt.id !== resource.id);
                    }
                });
                this.currentResourcePage = 1;
                this.currentResourcePageOtherResourceTypes = 1;

                this.setResourcesToShow();
                this.setResourcesOtherResourceTypesToShow();
            },
            null, true);
    }
}