import * as Constants from './../utils/constants';
import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

import { OpenActivities } from './components/openActivities';
import { PlannedActivities } from './components/plannedActivities';
import { PlanboardCounters } from './components/planboardCounters';
import { PlanboardResources } from './components/planboardResources';
import { PlanboardSplitters } from './components/planboardSplitters';

import { MainController } from './controls/mainController';

import { ActivityInfo } from './drawing/activityInfo';

import { ActivityType } from './entities/activitytype';
import * as Initialization from './entities/initialization';
import { Planboard } from './entities/planboard';

import * as Globals from './utils/globals';
import { ObjectList } from './utils/objectlist';
import { TimeSpan } from './utils/timespan';
import { WebApi } from './utils/webapi';


export var PlanboardCtrl = [
    "$scope", "$timeout", "$state", "$window", "$rootScope", "$filter", "translationService", "pageStartService", "modalConfirmationWindowService", "userService", "$http", "planningStatusService", "configurationService",
    function ($scope, $timeout, $state, $window, $rootScope, $filter, translationService, pageStartService, modalConfirmationWindowService, userService, $http, planningStatusService, configurationService) {
        // Put text label object on scope.
        translationService.getTextLabels($scope);

        var navbar = $("#mainHeader");
        var mainCanvasDiv = $("#mainCanvasDiv");
        var popupWidthDiv = $("#popupWidth");
        var childViewsDiv = $("#childViews");
        var dimmedDiv = $("#dimmedDiv");
        var buttonBarDiv = $("#buttonBar");
        var buttonBarContainer = $("#buttonBarContainer");
        var buttonBarContents = $("#buttonBarContents");
        var popupMenuDiv = $("#popupMenu");
        var popupMenuItemsDiv = $("#popupMenuItems");
        var buttonBarTimer = null;
        var saveCurrentDateFromPlanboard = null;
        var controlIsDroppedDown = [];
        var dialogToken = "planboard";
        var automaticSaveDelay = 5000;
        var webApiCallsWaitDelay = 500;
        var planboard = Planboard;
        var planSplitter = PlanboardSplitters;
        var previousReadOnlyState = null;
        var lastPlanAreaInnerLeft = null; // used by onDrawEnd to quickly test if anything needs to be done
        var lastPlanAreaColNr = null; // used by onDrawEnd to test if the currently visible column number changed
        var useResourceViolationsCache = false; // feature flag to use the violations for resources cache
        var clearSelectedActivityFromPalette = false; // feature flag to clear the selected activity type
        planboard.cancelInitialResources = false;
        planboard.textLabels = $scope.textLabels;
        var suspendPlanboardUpdatesWhenIdle = false; // feature flag to suspend the web socket events on idle time
        var inactivityTimeDelay = 60000;
        var idleTimer = null;
        var suspendPlanboardOnIdleTimeEvents = ["mousedown", "mousemove", "keypress", "scroll", "load", "touchstart"];
        var supendOnIdleTimeDestroyed = false;
        var featurePlanboardAPlanningBoard = false;
        var featureUseActivityRequestHandling = false;
        var skipWebSocketEvents = false;
        var eventHappenedWhenSuspended = false; // keep track of web sockets events
        var resumeDialogCurrentlyDisplayed = false; // store if the resume dialog is already displayed
        var resourceTypes = Object.create(null);
        var urlGetResourceTypes = "api/ResourceTypes/ForPlanningBoard";
        var userSelectedActivityTypes = [];
        var categoriesIds = [];
        var box = "☐";
        var checkbox = "☑";
        var dropdownActivityTypesNoneId = -Globals.maxInt + 1;
        var dropDownActivityTypesFilterId = -Globals.maxInt + 2;
        var dropDownActivityTypesSortId = -Globals.maxInt + 3;
        var applyActivityTypeFilterTimer = null;
        var saveCurrentDate = false;

        $scope.currentLeftDatePlanboard = null;
        $scope.scenarioId = 1; // Planning scenario default
        $scope.planboardVisible = "hidden";
        $scope.showSpinner = false; // indicates if the spinner it is visible or not
        $scope.webApiCallsIconDisplay = false; // indicates if the icon for a running web api call should be displayed or not
        $scope.webApiCallsTimerStarted = false; // indicates if a timer to show the web api calls has already started
        $scope.numberWebApiCalls = 0; // the number of webApi calls the planboard is waiting for
        $scope.buttonBarMinHeight = Math.max(Globals.fontHeight / 2, 8); // minimum visible height of the button bar in minimized state
        $scope.buttonBarHeightSet = (Globals.fontHeight * 2) + 4; // initialized height of the button bar in maximized state
        $scope.buttonBarHeight = $scope.buttonBarHeightSet + $scope.buttonBarMinHeight; // actual height of the button bar in maximized state
        $scope.buttonBarScreenY = 0; // Y screen position of the button bar
        $scope.buttonBarOffsetY = $scope.buttonBarMinHeight - $scope.buttonBarHeight; // current position offset of the button bar
        $scope.buttonBarOffsetX = 0; // X screen position of the button bar
        $scope.actTypeDropDownMaxWidth = 300;
        $scope.actTypeDropDownMaxHeight = 300;
        $scope.startDate = new Date();
        $scope.actTypes = new Dictionary();
        $scope.actTypesFiltered = new Dictionary();
        $scope.resourceSelections = new Dictionary(); // one selection for each readable resource type
        $scope.resourceSelectionsLoaded = false;
        $scope.scenarios = new Dictionary();
        $scope.menuDummyId = 0; // id for menu dropdowntree buttons, not actually used anywhere else than in the html view
        $scope.lineHeightMenu = new Dictionary(); // menu items for changing the line height in the planboard
        $scope.countersMenu = new Dictionary(); // menu items for resource counters
        $scope.zoomMenu = new Dictionary(); // menu items for zoom levels
        $scope.toggleFilters = new Dictionary(); // toggle filters
        $scope.toggleSorting = new Dictionary(); // toggle sorting
        $scope.dropdownVersion = 0;
        $scope.openLeaveRequests = [];
        $scope.popupMenuLeft = 0; // clicked screen position, will show the popup menu to the left or right of this value
        $scope.popupMenuTop = 0; // clicked screen position, will show the popup menu to the top or bottom of this value
        $scope.popupMenuItems = []; // array of current menu items
        $scope.copyActivityId = -1; // the Id of the activity that was last cut or copied
        $scope.applyUserPrefActivityTypeFilterToPlanboard = false; // to store if filter should be applied to Planboard
        $scope.applyUserPrefActivityTypeFilterAndSort = false; // to store if filter should be applied to Planboard
        $scope.applyUserPrefActivityTypeFilterToOpen = false; // to store if filter should be applied to Open Activities
        $scope.userPrefFilterByLeafActivityTypes = false; // to store if filter should consider leaf ids
        $scope.showPlanningStatusAlert = false; // to store if the alert icon should be displayed
        $scope.cachedResourceViolations = new Dictionary(); // dictionary with resource id as key, the value is another dictonary with the week as key
        $scope.isNonProductionEnvironment = false; // indicates if the current environment is not the production one
        $scope.userFilterAndSorting = [];
        $scope.lineHeightValue = 0; 
        $scope.addMode = false;
        $scope.addModeButtonVisible = false;
        $scope.applyFilterPaletteForActivityTypes = false; // to store if filter palette should be applied to Planboard
        $scope.applySortPaletteForActivityTypes = false; // to store if sort palette should be applied to Planboard

        planningStatusService.subscribe($scope, function planningStatusChanged() {
            $scope.loadPlanningStatus();
            planningStatusService.updatePlanningStatus(false);
        });

        var commonSvc = pageStartService.initialize($scope, null, dialogToken); // create an object that handles common functions and dialogs

        var skipWebSocketEventsOnIdleTime = function () {
            if (!supendOnIdleTimeDestroyed && !resumeDialogCurrentlyDisplayed) {
                skipWebSocketEvents = true;
                eventHappenedWhenSuspended = false;

                resumeDialogCurrentlyDisplayed = true;
                commonSvc.showDialog(
                    $scope.textLabels.PLANBOARD_SUSPENDED,
                    $scope.textLabels.PLANBOARD_SUSPENDED_DESCRIPTION,
                    $scope.textLabels.RESUME,
                    function () {
                        if (eventHappenedWhenSuspended) {
                            $scope.refreshClick(true);
                        }

                        resumeDialogCurrentlyDisplayed = false; 
                        skipWebSocketEvents = false;
                    }
                );
            }
        }

        var resetIdleTimer = function (e) {
            if (skipWebSocketEvents && !resumeDialogCurrentlyDisplayed) {
                skipWebSocketEvents = false;
            }

            if (suspendPlanboardUpdatesWhenIdle) {
                clearTimeout(idleTimer);
                idleTimer = setTimeout(skipWebSocketEventsOnIdleTime, inactivityTimeDelay);
            }
        }

        var removeIdleUserEventListeners = function () {
            if (suspendPlanboardUpdatesWhenIdle) {
                clearTimeout(idleTimer);
                idleTimer = undefined;

                suspendPlanboardOnIdleTimeEvents.forEach(function (name) {
                    window.removeEventListener(name, resetIdleTimer, false);
                });
            }
        }

        var handleInactivityTime = function () {

            if (suspendPlanboardUpdatesWhenIdle) {
                Planboard.onMouseMove = function () {
                    resetIdleTimer();
                }
                suspendPlanboardOnIdleTimeEvents.forEach(function (name) {
                    window.addEventListener(name, resetIdleTimer, false);
                });
            }
        };

        var initializeUserSettings = function () {
            $scope.scenarioId = $scope.dropDownScenarioId = planboard.scenarioId = userService.getDisplaySettingNumber("planboard.scenarioId", planboard.scenarioId);
            planboard.zoomLevel = userService.getDisplaySettingNumber("planboard.zoomLevel", planboard.zoomLevel);
            planboard.sortByResourcePropertyIds = userService.getDisplaySetting("planboard.sortByResourcePropertyId", "-1");
            planboard.sortDescending = userService.getDisplaySettingSwitch("planboard.sortDescending");
            planboard.applyUnitFilterToResources = userService.getDisplaySettingSwitch("planboard.applyUnitFilterToResources", false);
            planboard.applyResourcesToHide = userService.getDisplaySettingSwitch("planboard.applyResourcesToHide", false);
            planboard.applySkillsFilter = userService.getDisplaySettingSwitch("planboard.applySkillsFilter", false);
            planboard.applyCustomResourceFilter = userService.getDisplaySettingSwitch("planboard.applyResourceFilterInPlanboard", false);
            $scope.buttonBarPinned = userService.getDisplaySettingSwitch("planboard.buttonBarPinned");
            $scope.selectedActTypeId = clearSelectedActivityFromPalette ? dropdownActivityTypesNoneId : userService.getDisplaySettingNumber("planboard.selectedActTypeId", dropdownActivityTypesNoneId);
            $scope.resourceSelectionId = userService.getDisplaySettingNumber("planboard.resourceSelectionId", -1);
            planboard.planningStatusHeight = userService.getDisplaySettingSwitch("planboard.showPlanningStatusInPlanboard", false) ? 4 : 0;
            if ($scope.resourceSelectionId !== 0) planboard.activities.activateResourceSelection($scope.resourceSelectionId, false);
            planboard.selectedActivityTypeId = $scope.selectedActTypeId;
            planboard.dayStartHour = userService.getDisplaySettingNumber("planboard.dayStartHour", planboard.dayStartHour);
            planboard.dayEndHour = userService.getDisplaySettingNumber("planboard.dayEndHour", planboard.dayEndHour);
            planSplitter.splitResourceWidth = userService.getDisplaySettingNumber("planboard.splitResourceWidth", planSplitter.splitResourceWidth);
            planSplitter.splitUnplannedHeight = userService.getDisplaySettingNumber("planboard.splitUnplannedHeight", planSplitter.splitUnplannedHeight);
            planSplitter.splitCountersWidth = userService.getDisplaySettingNumber("planboard.splitCountersWidth", planSplitter.splitCountersWidth);
            planboard.applyUserDisplayPreferences();
            $scope.applyUserPrefActivityTypeFilterToPlanboard = userService.getDisplaySettingSwitch("planboard.applyActivityFilterInPlanboard", $scope.applyUserPrefActivityTypeFilterToPlanboard);
            $scope.applyUserPrefActivityTypeFilterAndSort = userService.getDisplaySettingSwitch("planboard.applyActivityTypeFilterSort", $scope.applyUserPrefActivityTypeFilterAndSort);
            $scope.applyUserPrefActivityTypeFilterToOpen = userService.getDisplaySettingSwitch("planboard.applyActivityFilterToOpen", $scope.applyUserPrefActivityTypeFilterToOpen);
            $scope.userPrefFilterByLeafActivityTypes = userService.getDisplaySettingSwitch("planboard.filterByLeafActivityTypes", $scope.userPrefFilterByLeafActivityTypes);
            $scope.applyFilterPaletteForActivityTypes = userService.getDisplaySettingSwitch("planboard.applyActivityTypeFilter", $scope.applyFilterPaletteForActivityTypes);
            $scope.applySortPaletteForActivityTypes = userService.getDisplaySettingSwitch("planboard.applyActivityTypeSort", $scope.applySortPaletteForActivityTypes);
            $scope.currentLeftDatePlanboard = userService.getDisplaySetting("planboard.currentLeftDate", $scope.currentLeftDatePlanboard);
            initializePlanboardWithLeftDate($scope.currentLeftDatePlanboard);
        }

        /**
         * Clears the current activity type selection from the palette if that feature flag is enabled.
         */
        var clearCurrentActivityTypeSelection = function () {
            if (clearSelectedActivityFromPalette) {
                $scope.selectedActTypeId = dropdownActivityTypesNoneId;
                planboard.selectedActivityTypeId = $scope.selectedActTypeId;
            }
        }

        /**
         * Set the color for the scenario
         */
        var setScenarioColor = function () {
            if (!planboard || !planboard.split1) {
                return;
            }

            if ($scope.scenarioId != 1) {
                planboard.split1.color = Globals.orangeWarningColor;
            }
            else
                planboard.split1.color = Globals.face3DColor;
        }

        /**
         * Loads scenarios for the scenario dropdown menu.
         */
        var loadScenarios = function () {
            commonSvc.loadData("api/Scenarios", null,
                function (response, loadInto) {
                    $scope.scenarios.clear();
                    if ($scope.userHasPermission('ScenarioManagement'))
                        $scope.scenarios.add(0, { id: 0, order: -2, displayName: $scope.textLabels.SCENARIO_MANAGEMENT });
                    $scope.scenarios.add(1, { id: 1, order: -1, displayName: $scope.textLabels.SCENARIO_TYPE_PLANNING });
                    for (var i = 0; i < loadInto.length; i++) $scope.scenarios.add(loadInto[i].id, loadInto[i]);
                    // Set the orange warning color to the planboard splitters if in a scenario
                    setScenarioColor();
                    planboard.redrawAll();
                },
                null, true, false);
        }

        var planningStatusTimer = null;
        var planningStatusDelay = 5000; // how long to wait before submitting the request for refreshing the planningStatus of resources
        var delayedRefreshTimer = null;
        var reloadActivitiesForResourceGroups = []; // for what resourceGroups data should be reloaded
        var reloadActivitiesForResourceIdList = []; // for what resourceIds data should be reloaded
        var lastResourceFilter = {}; // the last used resource filter

        /**
         * Loads planning status for all readable resources
         */
        $scope.loadPlanningStatus = function () {
            if (planningStatusTimer != null) $timeout.cancel(planningStatusTimer);
            planningStatusTimer = $timeout(function () {
                var postData = {
                    organizationUnitId: 0, // not for any particular organization unit, just for all that the user can see
                    date: Timezone.dateToStr(TimeSpan.today.addDays(27).toDate())
                };
                commonSvc.post("api/Resources/GetPlanningStatus", postData,
                    function (response) {
                        if (response && response.data)
                            for (var status in response.data) {
                                if (status == 1) {
                                    if (response.data[status] > 0) {
                                        // there are resources with planning status, so not yet everyone is at least published
                                        $scope.showPlanningStatusAlert = true;
                                        break;
                                    } else {
                                        $scope.showPlanningStatusAlert = false;
                                        break;
                                    }
                                }
                            }

                        $scope.showSpinner = false;
                    },
                    function () {
                        $scope.showSpinner = false;
                    }, false);
            }, planningStatusDelay);
        }

        var delayedRefresh = function (delay) {
            if (delayedRefreshTimer != null) $timeout.cancel(delayedRefreshTimer);

            delayedRefreshTimer = $timeout(function () {
                var redrawPlanboard = false;
                if (reloadActivitiesForResourceGroups.length > 0) {
                    // make planboard reload data for a select number of resource groups
                    var resourceGroups = reloadActivitiesForResourceGroups;
                    var resourceIdList = reloadActivitiesForResourceIdList;
                    reloadActivitiesForResourceGroups = [];
                    reloadActivitiesForResourceIdList = [];
                    for (var i = 0; i < resourceGroups.length; i++)
                        planboard.activities.resetDaysReceived(null, null, null, resourceGroups[i], resourceGroups[i] < 0, true, resourceIdList);
                    redrawPlanboard = true;
                }
                if (redrawPlanboard) planboard.redrawAll();
            }, delay);
        }

        var activityRefreshPending = false; // if there is currently a refresh pending for activities
        var activityRefreshIsGroup = false; // if the current pending activities contains at least one group refresh
        var activityRefreshIdObjectList = new ObjectList(); // object list with unique activity ids
        var activityRefreshDelay = 2000; // how long to wait before requesting the new activity data from the webApi

        /**
        * start a delayed refresh action for activities based upon a list of root activity ids
        * @param activityIds Array of root activity ids
        */
        var refreshChangedActivities = function (activityIds) {
            if (activityIds == null || activityIds.length == null || activityIds.length <= 0) return; // nothing to do

            for (var i = activityIds.length - 1; i >= 0; i--) // add the array of ids to the object list with unique ids
                activityRefreshIdObjectList.addObject(activityIds[i], 1);

            if (activityRefreshPending) return; // return if we still have a pending request timer running

            // start a timer with a pending request for activities
            activityRefreshPending = true;
            $timeout(function () {
                activityRefreshPending = false;
                var isGroup = activityRefreshIsGroup; // remember in a temporary variable if this was a group refresh or a single leaf refresh
                var objectIdList = activityRefreshIdObjectList; // make a new temporary variable reference the activityRefreshIdObjectList
                activityRefreshIdObjectList = new ObjectList(); // create a new empty activityRefreshIdObjectList for new activity refresh requests
                activityRefreshIsGroup = false; // reset this variable for new activity refresh requests
                var activityIdArray = objectIdList.getKeys(); // convert to array of activity Ids
                if (activityIdArray.length === 1 && !isGroup)
                    planboard.readActivity(activityIdArray[0], true); // request single leaf activity
                else
                    planboard.readActivitiesWithRootIds(activityIdArray); // request multiple groups
            }, activityRefreshDelay);
        }

        /**
        * function that will be called when a websocket event for activities is received
        * @param message The message given by the WebSocket
        */
        var activityEvent = function (message) {
            if (skipWebSocketEvents) {
                eventHappenedWhenSuspended = true;
                return;
            }

            console.log("Received activity event.", message);

            var eventNr = Number(message.event);

            // filter messages by the scenario the user is looking at
            if (message.scenarioId != undefined &&
                Number(message.scenarioId) !== $scope.scenarioId &&
                eventNr !== 7 &&
                eventNr !== 8) return; // TODO: make this tidier, for now this ensures that the scenario dropdown is updated when a scenario is deleted or added

            // Add scenario to dropdown if it is not yet there.
            if (eventNr === 7 && !$scope.scenarios.containsKey(message.scenarioId)) {
                loadScenarios();
                return; 
            }

            // filter messages by dates
            if (((eventNr >= 1 && eventNr <= 7) || eventNr === 9) && message.firstActivityStart != null && message.lastActivityEnd != null) {
                var startDate = new Date(message.firstActivityStart);
                var enddate = new Date(message.lastActivityEnd);
                if (!planboard.activities.isAnyDataRequested(startDate, enddate)) {
                    return;
                }
            }

            // filter messages by resources, this only has noticable performance effect on activities that are completely filled
            if (eventNr >= 1 && eventNr <= 7 && message.resourceIds != null && message.resourceIds.length > 0) {
                var anyResourceKnown = false;
                for (var i = message.resourceIds.length - 1; i >= 0; i--) {
                    var resourceId = Number(message.resourceIds[i]);
                    // if resourceId <=0 then there is no resource planned on the leaf activity and it needs to be loaded to show in the tray
                    if (resourceId <= 0 || planboard.activities.getResource(resourceId, true) != null) {
                        anyResourceKnown = true;
                        break;
                    }
                }
                if (!anyResourceKnown) {
                    return;
                }
            }

            var activityIds = message.activityIds;
            switch (eventNr) {
                case 1: // added
                case 2: // modified
                    refreshChangedActivities(activityIds);
                    break;

                case 3: // deleted - this should not occur, this value is currently not used
                    for (let i = 0; i < activityIds.length; i++) {
                        planboard.removeActivityFromMemory(activityIds[i], true);
                    }
                    break;

                case 4: // group added
                case 5: // group modified
                    activityRefreshIsGroup = true;
                    refreshChangedActivities(activityIds);
                    break;

                case 6: // group deleted
                    for (let i = 0; i < activityIds.length; i++) {
                        planboard.removeActivityGroupFromMemory(activityIds[i], true);
                    }
                    break;

                case 7: // refresh scenario
                    // Refresh the scenario for the message if the user is looking at it.
                    if ($scope.scenarioId === Number(message.scenarioId)) {
                        $scope.onScenarioSelectionChanged($scope.scenarioId);
                    }
                    break;

                case 8: // refresh everything for resource
                    if (message.resourceIds) {
                        let refresh = false;
                        message.resourceIds.forEach(resourceId => {
                            // clear counters for this resource
                            planboard.activities.clearCountersForResource(resourceId);

                            // find the resource group for this resource
                            const groupIndex = planboard.activities.getResourceGroupIndex(resourceId);

                            if (groupIndex != null) {
                                if (reloadActivitiesForResourceGroups.indexOf(groupIndex) < 0)
                                    reloadActivitiesForResourceGroups.push(groupIndex);
                                if (reloadActivitiesForResourceIdList.indexOf(resourceId) < 0)
                                    reloadActivitiesForResourceIdList.push(resourceId);

                                refresh = true;
                            }
                        });

                        if (refresh) {
                            // restart delayed refresh
                            delayedRefresh(5000);
                        }
                    }
                    break;

                case 9:
                    planboard.refreshOpenActivities();
            }
        }

        /**
        * function that will be called when a websocket event for activity types is received
        * @param message The message given by the WebSocket
        */
        var activityTypeEvent = function (message) {
            // clear the activity type selection if that activity type changed. this needs to be done even when skipWebSocketEvents is true, because resuming does not clear the activity type selection.
            if (planboard.selectedActivityTypeId == message.activityTypeId) {
                clearCurrentActivityTypeSelection();
            }

            if (skipWebSocketEvents) {
                eventHappenedWhenSuspended = true;
                return;
            }

            console.log("Received activity type event.", message);

            var activityTypeId = message.activityTypeId;
            switch (Number(message.event)) {
                case 1: // added
                case 2: // modified
                    // (re)read type

                    // Altijd doen: in Planboard.activityTypes het type laden.
                    // Doen indien roottype: aan $scope.actTypes toevoegen, in goede category.

                    // load activitytype categories
                    commonSvc.loadData("api/ActivityTypes/" + activityTypeId, null,
                        function (response) {
                            $timeout(function () {
                                var actType = response.data;

                                var planBoardActType = new ActivityType(actType.id,
                                    actType.parentId,
                                    actType.shortName,
                                    "#" + actType.backColor,
                                    "#" + actType.textColor,
                                    actType.categoryId);

                                planBoardActType.displayName = actType.displayName;
                                planBoardActType.resourceTypeIdList = actType.resourceTypeIdList;
                                planBoardActType.defaultTimeSlotList = actType.defaultTimeSlotList;
                                planboard.activityTypes.addObject(planBoardActType.id, planBoardActType);
                                planboard.viewChanged();
                                planboard.timedRefresh();

                                if (actType.parentId == null && actType.maxPermissionForCurrentUser >= 2) {

                                    var categoryId = actType.categoryId + Globals.maxInt;
                                    if (!$scope.actTypes.value(categoryId))
                                        $scope.actTypes.add(categoryId,
                                            { id: categoryId, displayName: "" + categoryId });

                                    actType.parentId = categoryId;
                                    $scope.actTypes.add(actType.id, actType);
                                    $scope.dropdownVersion++;
                                }
                            },
                                0);
                        },
                        function (response) {
                            if (response && response.status === 403) {
                                // ignore forbidden warning
                            } else {
                                // show the normal popup message for any other status
                                commonSvc.httpErrorResponse(response, function () { });
                            }
                        }, true, false);

                    break;

                case 3: // deleted
                    planboard.activityTypes.removeObject(activityTypeId);
                    planboard.viewChanged();
                    planboard.timedRefresh();
                    $scope.actTypes.remove(activityTypeId);
                    $scope.dropdownVersion++;
                    if (planboard.selectedActivityTypeId === Number(activityTypeId)) {
                        planboard.selectedActivityTypeId = -1;
                        $scope.selectedActTypeId = dropdownActivityTypesNoneId;
                    }
                    break;
            }
        }

        /**
        * function that will be called when a websocket event for resource is received
        * @param message The message given by the WebSocket
        */
        var resourceEvent = function (message) {
            if (skipWebSocketEvents) {
                eventHappenedWhenSuspended = true;
                return;
            }

            console.log("Received resource event.", message);
            $scope.refreshResourceList($scope.resourceSelectionId, message.resourceIds);
        }

        /**
        * function that will be called when a websocket event for scenario is received
        * @param message The message given by the WebSocket
        */
        var scenarioEvent = function (message) {
            if (skipWebSocketEvents) {
                eventHappenedWhenSuspended = true;
                return;
            }

            console.log("Received scenario event.", message);

            if (message.event === "Deleted") {
                // select planning scenario if user was looking at the deleted scenario
                if ($scope.scenarioId === Number(message.scenarioId)) {
                    $scope.onScenarioSelectionChanged(1);
                }
                $scope.scenarios.remove(message.scenarioId);
            }
            else if (message.event === "Modified" && message.latestRequestStatus === "Completed") {
                var resourceIdList = planboard.activities.getResourceIdsInSelection($scope.resourceSelectionId);
                $scope.refreshViolations(resourceIdList);
            }
        }

        /**
         * Function than will be called when a websocket event for user settings is received.
         * @param message The received message.
         */
        var userEvent = function (message) {
            if (skipWebSocketEvents) {
                eventHappenedWhenSuspended = true;
                return;
            }

            console.log("Received user event.", message);

            switch (Number(message.event)) {
                case 1: // Resource filter changed.
                    $scope.refreshResourceList($scope.resourceSelectionId);
                    break;
                case 2: // Resource order changed.
                    planboard.sortByResourcePropertyIds = userService.getDisplaySetting("planboard.sortByResourcePropertyId", "-1");
                    planboard.sortDescending = userService.getDisplaySettingSwitch("planboard.sortDescending");
                    planboard.applyUnitFilterToResources = userService.getDisplaySettingSwitch("planboard.applyUnitFilterToResources", false);
                    planboard.applyResourcesToHide = userService.getDisplaySettingSwitch("planboard.applyResourcesToHide", false);
                    planboard.applySkillsFilter = userService.getDisplaySettingSwitch("planboard.applySkillsFilter", false);
                    planboard.applyCustomResourceFilter = userService.getDisplaySettingSwitch("planboard.applyResourceFilterInPlanboard", false);
                    if (planboard.sortByResourcePropertyId != lastResourceFilter.sortByResourcePropertyId ||
                        planboard.sortDescending != lastResourceFilter.sortDescending ||
                        planboard.applyUnitFilterToResources != lastResourceFilter.applyUnitFilterToResources ||
                        planboard.applyCustomResourceFilter != lastResourceFilter.applyCustomResourceFilter || 
                        planboard.applyResourcesToHide != lastResourceFilter.applyResourcesToHide ||
                        planboard.applySkillsFilter != lastResourceFilter.applySkillsFilter ||
                        planboard.sortByResourcePropertyIds != lastResourceFilter.sortByResourcePropertyIds) {
                        $scope.refreshResourceList($scope.resourceSelectionId);
                    }
                    break;
                case 3: // Organization unit selection changed.
                    planboard.applyUnitFilterToResources = userService.getDisplaySettingSwitch("planboard.applyUnitFilterToResources", false);
                    planboard.applyResourcesToHide = userService.getDisplaySettingSwitch("planboard.applyResourcesToHide", false);
                    planboard.applySkillsFilter = userService.getDisplaySettingSwtich("planboard.applySkillsFilter", false);
                    $scope.refreshResourceList($scope.resourceSelectionId);
                    break;
                case 4: // Resource property selection changed.
                    $scope.refreshResourceProperties();
                    break;
            }
        }

        //#region batch activity messages handler functions

        var batchActivityMessagesHandlerState = {
            messages: [],
            maxCooldown: null,
            polling: false
        };

        var fireAtRandomInterval = (min, max, callback) => {
            if (!batchActivityMessagesHandlerState.polling) {
                return;
            }

            var rand = Math.round(Math.random() * (max - min)) + min;
            $timeout(function () {
                callback();
                fireAtRandomInterval(min, max, callback);
            }, rand);
        }

        var captureBatchActivityMessages = (message) => {
            if (message.batchActionResult && batchActivityMessagesHandlerState.polling) {
                batchActivityMessagesHandlerState.messages.push(message);
            } else {
                activityEvent(message);
            }
        }

        var getMergedDate = (firstDate, secondDate, min) => {
            if (firstDate === null || secondDate === null) {
                return null;
            }

            var d1 = new Date(firstDate);
            var d2 = new Date(secondDate);

            if (min) {
                return d1.getTime() <= d2.getTime() ? firstDate : secondDate;
            } else {
                return d1.getTime() <= d2.getTime() ? secondDate : firstDate;
            }
        }

        var mergeRefreshScenarioMessages = (messages) => {
            var mergedRefreshScenarioMessages = [];
            var refreshScenarioMessages = messages.filter((msg) => Number(msg.event) === 7);
            var otherMessages = messages.filter((msg) => Number(msg.event) !== 7);

            for (var i = 0; i < refreshScenarioMessages.length; i++) {
                var merged = false;

                for (var ii = 0; ii < mergedRefreshScenarioMessages.length; ii++) {
                    if (mergedRefreshScenarioMessages[ii].scenarioId === refreshScenarioMessages[i].scenarioId) {
                        if (mergedRefreshScenarioMessages[ii].resourceIds === null ||
                            refreshScenarioMessages[i].resourceIds === null) {
                            mergedRefreshScenarioMessages[ii].resourceIds = null;
                        } else {
                            mergedRefreshScenarioMessages[ii].resourceIds =
                                mergedRefreshScenarioMessages[ii].resourceIds.concat(refreshScenarioMessages[i].resourceIds);
                        }
                        mergedRefreshScenarioMessages[ii].firstActivityStart = getMergedDate(
                            mergedRefreshScenarioMessages[ii].firstActivityStart,
                            refreshScenarioMessages[i].firstActivityStart, true);
                        mergedRefreshScenarioMessages[ii].lastActivityEnd = getMergedDate(
                            mergedRefreshScenarioMessages[ii].lastActivityEnd,
                            refreshScenarioMessages[i].lastActivityEnd, false);

                        merged = true;
                    }
                }

                if (merged === false) {
                    mergedRefreshScenarioMessages.push(refreshScenarioMessages[i]);
                }
            }

            return otherMessages.concat(mergedRefreshScenarioMessages);
        }

        var mergeYearPlanningMessages = (messages) => {
            var mergedYearPlanningMessages = [];
            var yearPlanningMessages = messages.filter((msg) => Number(msg.event) === 9);
            var otherMessages = messages.filter((msg) => Number(msg.event) !== 9);

            for (var i = 0; i < yearPlanningMessages.length; i++) {
                var merged = false;

                for (var ii = 0; ii < mergedYearPlanningMessages.length; ii++) {
                    if (mergedYearPlanningMessages[ii].scenarioId === yearPlanningMessages[i].scenarioId) {
                        mergedYearPlanningMessages[ii].firstActivityStart = getMergedDate(
                            mergedYearPlanningMessages[ii].firstActivityStart,
                            yearPlanningMessages[i].firstActivityStart, true);
                        mergedYearPlanningMessages[ii].lastActivityEnd = getMergedDate(
                            mergedYearPlanningMessages[ii].lastActivityEnd,
                            yearPlanningMessages[i].lastActivityEnd, false);

                        merged = true;
                    }
                }

                if (merged === false) {
                    mergedYearPlanningMessages.push(yearPlanningMessages[i]);
                }
            }

            return otherMessages.concat(mergedYearPlanningMessages);
        }

        var registerBatchActivityMessagesHandler = (batchBroadcastCooldown) => {
            batchActivityMessagesHandlerState.polling = true;
            batchActivityMessagesHandlerState.maxCooldown = batchBroadcastCooldown * 1000; // seconds to milliseconds

            fireAtRandomInterval(0, batchActivityMessagesHandlerState.maxCooldown,
                () => {
                    if (batchActivityMessagesHandlerState.messages.length === 0) {
                        return;
                    }

                    var handleMessages = batchActivityMessagesHandlerState.messages;
                    batchActivityMessagesHandlerState.messages = [];

                    var messages = mergeRefreshScenarioMessages(handleMessages);
                    messages = mergeYearPlanningMessages(messages);

                    while (messages.length > 0) {
                        var message = messages.shift();
                        activityEvent(message);
                    }
                });
        }

        var unregisterBatchActivityMessagesHandler = () => {
            batchActivityMessagesHandlerState.messages = [];
            batchActivityMessagesHandlerState.maxCooldown = null;
            batchActivityMessagesHandlerState.polling = false;
        }

        //#endregion


        // initialize menu items in the button bar
        var initializeMenuBarItems = function () {
            $scope.lineHeightMenu.add(1, { id: 1, text: $scope.textLabels.COLLAPSE_LINES });
            $scope.lineHeightMenu.add(2, { id: 2, text: $scope.textLabels.MAKE_LOWER });
            $scope.lineHeightMenu.add(3, { id: 3, text: $scope.textLabels.MAKE_HIGHER });
            $scope.lineHeightMenu.add(4, { id: 4, text: $scope.textLabels.EXPAND_ALL });
          
            $scope.countersMenu.add(1, { id: 1, text: $scope.textLabels.COUNTERS_WEEK });
            $scope.countersMenu.add(2, { id: 2, text: $scope.textLabels.COUNTERS_MONTH });
            $scope.countersMenu.add(3, { id: 3, text: $scope.textLabels.COUNTERS_YEAR });
            $scope.countersMenu.add(4, { id: 4, text: $scope.textLabels.COUNTERS_SET_CUSTOM });
            $scope.countersMenu.add(1000, { id: 1000, text: $scope.textLabels.COUNTERS_HIDE });

            $scope.zoomMenu.add(1, { id: 1, text: "1 " + $scope.textLabels.DAY.toLowerCase() });
            $scope.zoomMenu.add(5, { id: 5, text: "5 " + $scope.textLabels.DAYS.toLowerCase() });
            $scope.zoomMenu.add(7, { id: 7, text: "7 " + $scope.textLabels.DAYS.toLowerCase() });

            $scope.toggleFilters.add(1, { id: 1, text: $scope.textLabels.PLANBOARD_FILTERS });
            $scope.toggleFilters.add(2, { id: 2, text: planboard.applyUnitFilterToResources ? checkbox + " " + $scope.textLabels.PLANBOARD_FILTER_RESOURCE_ORGANIZATION_UNITS : box + " " + $scope.textLabels.PLANBOARD_FILTER_RESOURCE_ORGANIZATION_UNITS });
            $scope.toggleFilters.add(3, { id: 3, text: planboard.applySkillsFilter ? checkbox + " " + $scope.textLabels.PLANBOARD_FILTER_RESOURCE_SKILLS : box + " " + $scope.textLabels.PLANBOARD_FILTER_RESOURCE_SKILLS });
            $scope.toggleFilters.add(4, { id: 4, text: planboard.applyResourcesToHide ? checkbox + " " + $scope.textLabels.PLANBOARD_HIDE_RESOURCES : box + " " + $scope.textLabels.PLANBOARD_HIDE_RESOURCES });
            $scope.toggleFilters.add(5, { id: 5, text: $scope.applyUserPrefActivityTypeFilterToPlanboard ? checkbox + " " + $scope.textLabels.PLANBOARD_FILTER_ACTIVITY_TYPE : box + " " + $scope.textLabels.PLANBOARD_FILTER_ACTIVITY_TYPE });
            $scope.toggleFilters.add(6, { id: 6, text: $scope.applyUserPrefActivityTypeFilterToOpen ? checkbox + " " + $scope.textLabels.PLANBOARD_OVERVIEW_FILTER_ACTIVITY_TYPE : box + " " + $scope.textLabels.PLANBOARD_OVERVIEW_FILTER_ACTIVITY_TYPE });

            $scope.toggleSorting.add(1, { id: 1, text: $scope.textLabels.PLANBOARD_SORTING });
            $scope.toggleSorting.add(2, { id: 2, text: planboard.applyCustomResourceFilter ? checkbox + " " + $scope.textLabels.PLANBOARD_SORT_RESOURCES : box + " " + $scope.textLabels.PLANBOARD_SORT_RESOURCES });
        }

        // show web API calls started icon after a few milliseconds
        var showWebApiCallsStartedIcon = function (waitTime) {
            $scope.webApiCallsTimerStarted = true;
            $timeout(function () {
                if ($scope.numberWebApiCalls > 0 && $scope.webApiCallsIconDisplay === false) {
                    $scope.webApiCallsIconDisplay = true;
                }
            }, waitTime);
        }

        // hide web API calls started icon after a few milliseconds
        var hideWebApiCallsStartedIcon = function (waitTime) {
            $timeout(function () {
                if ($scope.numberWebApiCalls <= 0) {
                    $scope.webApiCallsTimerStarted = false;
                    if ($scope.webApiCallsIconDisplay !== false) {
                        $scope.webApiCallsIconDisplay = false;
                    }
                }
            }, waitTime);
        }

        // increase the number of webapi calls
        var incrementWebApiCalls = function () {
            $scope.numberWebApiCalls++;
            if (!$scope.webApiCallsTimerStarted && $scope.numberWebApiCalls > 0) {
                showWebApiCallsStartedIcon(webApiCallsWaitDelay);
            }
        }

        // decrease the number of webapi calls
        var decrementWebApiCalls = function () {
            $scope.numberWebApiCalls = Math.max($scope.numberWebApiCalls - 1, 0);
            if ($scope.numberWebApiCalls <= 0) {
                hideWebApiCallsStartedIcon(0);
            }
        }

        // apply the activityType filter for the current resourceSelection
        var applyActivityTypeFilter = function (selectionId) {
            if (applyActivityTypeFilterTimer != null) {
                clearTimeout(applyActivityTypeFilterTimer);
            }
            applyActivityTypeFilterTimer = setTimeout(function () {
                planboard.applyActivityTypeFilter(null, $scope.applyUserPrefActivityTypeFilterToPlanboard, $scope.applyUserPrefActivityTypeFilterToOpen);
                if (selectionId == undefined) selectionId = $scope.resourceSelectionId; // use current selection from scope
                // build an array of activityTypeIds for activityTypes that needs any in resourceTypeIdList
                var actTypeIdList = [];
                planboard.activityTypes.forEach(function (id, item) {
                    // selectionId is identical to a resourceTypeId, test here if the activityType needs a resource of this resourceTypeId
                    if (item.resourceTypeIdList.indexOf(selectionId) >= 0) actTypeIdList.push(id);
                });
                OpenActivities.applyActivityTypeFilter("resourceTypeFilter", actTypeIdList);
            }, 200);
        }

        // get the largest (negative) amount the button bar contents can be scrolled to the left
        var getButtonBarMinOffsetX = function () {
            return -(Math.floor(Math.max(0, buttonBarContents[0].clientWidth - buttonBarContainer[0].clientWidth)));
        }

        // scroll the button bar contents to the left/right
        var scrollButtonBarContents = function (newvalue) {
            newvalue = Math.floor(Math.min(0, Math.max(getButtonBarMinOffsetX(), newvalue)));
            if (newvalue === $scope.buttonBarOffsetX) return;
            $scope.buttonBarOffsetX = newvalue;
            buttonBarContents.stop().animate({ left: "" + $scope.buttonBarOffsetX + "px" }, 300);
        }

        // function to resize the planboard element
        var doResizePlanboard = function () {
            $timeout(function () {
                var newTop = navbar.outerHeight(false);
                var newWidth = Math.floor(window.innerWidth);
                var dimmedHeight = Math.floor(Math.max(window.innerHeight - newTop, 0));

                // position dimmed div just under the navbar (dimmed is normally full screen)
                dimmedDiv.css("top", "" + newTop + "px");
                dimmedDiv.css("height", "" + dimmedHeight + "px");

                // position the button bar
                $scope.buttonBarScreenY = newTop;
                $scope.buttonBarHeight = $scope.buttonBarHeightSet + ($scope.buttonBarPinned ? 0 : $scope.buttonBarMinHeight);
                var buttonBarTop = $scope.buttonBarPinned ? newTop : newTop + $scope.buttonBarOffsetY;
                buttonBarDiv.css("top", "" + buttonBarTop + "px");
                buttonBarDiv.css("width", "" + newWidth + "px");
                buttonBarDiv.css("height", "" + $scope.buttonBarHeight + "px");
                if ($scope.buttonBarOffsetX !== 0) scrollButtonBarContents($scope.buttonBarOffsetX);

                newTop = Math.floor(newTop + ($scope.buttonBarPinned ? $scope.buttonBarHeight : $scope.buttonBarMinHeight));
                $scope.actTypeDropDownMaxHeight = Math.max(Math.floor(window.innerHeight - (newTop + $scope.buttonBarHeight * 2)), 200);
                $scope.actTypeDropDownMaxWidth = Math.max(Math.floor(newWidth * 0.2), 300);
                var newHeight = Math.floor(window.innerHeight - newTop);
                // position the planboard
                mainCanvasDiv.css("top", "" + newTop + "px");
                mainCanvasDiv.css("height", "" + newHeight + "px");
                mainCanvasDiv.css("width", "" + newWidth + "px");
                mainCanvasDiv[0].height = newHeight;
                mainCanvasDiv[0].width = newWidth;

                // position the child views (absolute in relation to dimmedDiv)
                var childViewsTop = Math.floor($scope.buttonBarHeightSet * 0.5);
                childViewsDiv.css("top", "" + childViewsTop + "px");
                childViewsDiv.css("left", "" + popupWidthDiv.offset().left + "px");
                childViewsDiv.css("width", "" + popupWidthDiv.outerWidth() + "px");
                childViewsDiv.css("max-height", "" + Math.floor(Math.max(dimmedHeight - childViewsTop * 2, 0)) + "px");

                // resize OMRP planboard
                Initialization.resizeAndRedraw();
                if ($scope.planboardVisible !== "visible") $scope.planboardVisible = "visible";
            }, 0);
        }

        // slide in the button bar
        var showButtonBar = function () {
            if (buttonBarTimer) { $timeout.cancel(buttonBarTimer); buttonBarTimer = null; }
            if ($scope.buttonBarPinned) return;
            if ($scope.buttonBarOffsetY === 0) return;
            $scope.buttonBarOffsetY = 0;
            buttonBarDiv.stop().animate({ top: "" + ($scope.buttonBarScreenY + $scope.buttonBarOffsetY) + "px" }, 300);
        }

        // slide out the button bar
        var hideButtonBar = function () {
            if (buttonBarTimer) { $timeout.cancel(buttonBarTimer); buttonBarTimer = null; }
            if ($scope.buttonBarPinned) return;
            for (var i = 0; i < controlIsDroppedDown.length; i++) if (controlIsDroppedDown[i]) return;
            if ($scope.buttonBarOffsetY === $scope.buttonBarMinHeight - $scope.buttonBarHeight) return;
            $scope.buttonBarOffsetY = $scope.buttonBarMinHeight - $scope.buttonBarHeight;
            buttonBarDiv.stop().animate({ top: "" + ($scope.buttonBarScreenY + $scope.buttonBarOffsetY) + "px" }, 300);
        }

        // hide the button bar with timeout or show the button bar
        var hideShowButtonBar = function (visible) {
            if (buttonBarTimer) { $timeout.cancel(buttonBarTimer); buttonBarTimer = null; }
            if (!visible) buttonBarTimer = $timeout(function () { hideButtonBar() }, 2000);
            else showButtonBar();
        }

        // variables for moving the childView with the mouse
        var childViewMouseDownX = null;
        var childViewMouseDownY = null;
        var childViewStartPosX = null;
        var childViewStartPosY = null;

        // callback function for moving the childView
        var childViewMouseMove = function (e) {
            var div = document.getElementById("childViews");
            if (div == null) return;
            if (childViewMouseDownX == null || childViewMouseDownY == null) {
                childViewStartPosX = parseInt(div.style.left.replace("px", ""));
                childViewStartPosY = parseInt(div.style.top.replace("px", ""));
                childViewMouseDownX = e.clientX;
                childViewMouseDownY = e.clientY;
                return;
            }
            div.style.left = (childViewStartPosX + e.clientX - childViewMouseDownX) + "px";
            div.style.top = Math.max((childViewStartPosY + e.clientY - childViewMouseDownY), 0) + "px";
            document.getSelection().removeAllRanges();
            e.preventDefault();
        }

        // callback function for stopping moving of the childView
        var childViewMouseUp = function (e) {
            window.removeEventListener("mousemove", childViewMouseMove, false);
            window.removeEventListener("mouseup", childViewMouseUp, false);
        }

        // callback function for starting moving of the childView
        var childViewMouseDown = function (e) {
            if (e.target.id !== "childViews" && e.target.id !== "childViewTitle" && e.target.id !== "childViewHeader") return;
            childViewMouseDownX = null;
            childViewMouseDownY = null;
            window.addEventListener("mousemove", childViewMouseMove, false);
            window.addEventListener("mouseup", childViewMouseUp, false);
        }

        // reposition the popup menu if it falls outside the window
        var repositionPopupMenu = function (nextTimeout) {
            if ($scope.popupMenuItems.length === 0) return; // menu is empty
            var menuSize = popupMenuItemsDiv[0].getBoundingClientRect();
            if (menuSize.width === 0 && (nextTimeout == undefined || nextTimeout < 2000)) {
                // getBoundingClientRect returned outdated information, call this function again with an increasing timeout
                nextTimeout = nextTimeout == undefined ? 100 : nextTimeout * 2;
                $timeout(function () { repositionPopupMenu(nextTimeout); }, nextTimeout);
                return;
            }
            var x = $scope.popupMenuLeft;
            var y = $scope.popupMenuTop;
            if (menuSize.width > 0 && x + menuSize.width > window.innerWidth) x = Math.max(x - menuSize.width, 0);
            if (menuSize.height > 0 && y + menuSize.height > window.innerHeight) y = Math.max(y - menuSize.height, 0);
            popupMenuDiv[0].style.left = x.toString() + "px";
            popupMenuDiv[0].style.top = y.toString() + "px";
            popupMenuDiv[0].style.visibility = "visible";
        }

        // show the popup menu at a specific location and set the planboard to readOnly
        var showPopupMenu = function (screenX, screenY) {
            if ($scope.popupMenuItems.length === 0) return; // menu is empty
            if (previousReadOnlyState == null) previousReadOnlyState = planboard.readOnly;
            planboard.readOnly = true;
            $timeout(function () { // timeout to give angular time to do the repeater over $scope.popupMenuItems
                $scope.popupMenuLeft = screenX;
                $scope.popupMenuTop = screenY;
                ActivityInfo.hide();
                $timeout(function () { repositionPopupMenu(); }, 1);
            }, 1);
        }

        // hide the popup menu if it was visible, also can restore the planboard readOnly state
        var hidePopupMenu = function (restoreReadOnlyState) {
            if (restoreReadOnlyState) {
                if (previousReadOnlyState != null) planboard.readOnly = previousReadOnlyState;
                previousReadOnlyState = null;
            }
            if ($scope.popupMenuItems.length > 0) {
                popupMenuDiv[0].style.visibility = "hidden";
                $scope.popupMenuItems = [];
            }
        }

        // event to hide the popup menu if clicked anywhere else than in the planboard
        var windowMouseDownEvent = function (e) {
            if ($scope.popupMenuItems.length > 0)
                if (!$(e.target).hasClass("dropdown-menu") && $(e.target).parents(".dropdown-menu").length === 0)
                    hidePopupMenu(true);
        }

        // register events for planboard actions
        var registerPlanboardEvents = function () {
            // movable childviews
            var childViewsDiv = document.getElementById("childViews");
            if (childViewsDiv != null) childViewsDiv.addEventListener("mousedown", childViewMouseDown, false);

            // register event when user settings are loaded, in case someone uses the browser refresh
            userService.registerUserSettingsEvent(dialogToken, function () {
                planboard.cancelInitialResources = true; // planboard.ts might still be loading resources, we do not need to show these
                initializeUserSettings();
                $scope.onResourceSelectionChanged($scope.resourceSelectionId);
                doResizePlanboard();
                planboard.setZoom(planboard.zoomLevel, true);
                initializeMenuBarItems();
            });

            if (!userService.userSettingsLoaded()) {
                userService.retrieveDisplaySettings(true);
            }

            // when the planboard finishes loading activityTypes we can apply the filter for activityTypes
            planboard.registerEvent(planboard.onActivityTypesLoaded, dialogToken, function () {
                applyActivityTypeFilter();
            });

            // when the planboard changes it's most left date we can reload a list of active resources
            planboard.registerEvent(planboard.onLeftDateChanged, dialogToken, function () {
                if ($scope.resourceSelectionId !== 0) $scope.refreshResourceList($scope.resourceSelectionId);
                $scope.updateCounters();
            });

            // when the planboard is requesting activities we can trigger the show spinner action
            planboard.registerEvent(planboard.onRequestingActivities, dialogToken, function () {
                $scope.showSpinner = true;
            });
            
            // when the planboard finishes loading activities we can trigger the hide spinner action
            planboard.registerEvent(planboard.onActivitiesLoaded, dialogToken, function () {
                $scope.showSpinner = false;
                if ($scope.lineHeightValue !== 0 && featurePlanboardAPlanningBoard) {
                    $scope.onLineHeightChanged($scope.lineHeightValue);
                }
            });

            // remove and add the event listener for keyDown
            window.removeEventListener("keydown", $scope.keyDownEvent, false);
            window.addEventListener("keydown", $scope.keyDownEvent, false);

            // remove and add the event listerener for mouseDown
            window.removeEventListener("mousedown", windowMouseDownEvent, false);
            window.addEventListener("mousedown", windowMouseDownEvent, false);
        }

        // unregister events for planboard actions
        var unregisterPlanboardEvents = function () {
            var childViewsDiv = document.getElementById("childViews");
            if (childViewsDiv != null) childViewsDiv.removeEventListener("mousedown", childViewMouseDown, false);
            userService.unregisterUserSettingsEvent(dialogToken);
            planboard.unregisterEvent(planboard.onActivityTypesLoaded, dialogToken);
            planboard.unregisterEvent(planboard.onLeftDateChanged, dialogToken);
            if (planboard.activities) planboard.activities.cancelLazyLoadTimer();
            window.removeEventListener("keydown", $scope.keyDownEvent, false);
            window.removeEventListener("mousedown", windowMouseDownEvent, false);
        }

        // overwrite the typescript dateFormat function
        Globals.dateFormat = function (dt, format) {
            return $filter("date")(dt, format);
        }

        // overwrite the typescript dateToDisplayStr function
        Globals.dateToDisplayStr = function (dt, includeDate, includeTime) {
            return includeDate ? $filter("date")(dt, "EEE") + " " +
                $filter("date")(dt, "mediumDate").replace(dt.getFullYear().toString(), "").replace(",", "").replace(".", "").trim() +
                (includeTime ? " " + $filter("date")(dt, "HH:mm") : "")
                : $filter("date")(dt, "HH:mm");
        }

        // overwrite the typescript planboard.showMessage function
        Planboard.showMessage = function (title, text, buttonText, buttonAction) {
            commonSvc.showDialog(title, text, buttonText, buttonAction);
        }

        // overwrite the typescript planboard.showYesNoMessage function
        Planboard.showYesNoMessage = function (title, text, buttonYesAction, buttonNoAction) {
            commonSvc.showYesNoDialog(title, text, buttonYesAction, buttonNoAction);
        }

        // overwrite the typescript planboard.showQuestionMessage function
        Planboard.showQuestionMessage = function (title, text, buttonTextArray, buttonActionArray) {
            commonSvc.showModalQuestionDialog(title, text, buttonTextArray, buttonActionArray);
        }

        // updates the status for all activities in a group
        var updateLockStatusForActivityGroup = function (activityId) {
            var rootActivity = planboard.activities.getMainActivity(activityId);

            if (rootActivity) {
                rootActivity.status = rootActivity.status === Constants.StatusActivityLocked
                    ? Constants.StatusActivityPlanned
                    : Constants.StatusActivityLocked;

                var group = planboard.getAllActivitiesInGroup(rootActivity.id);
                for (var i = 0; i < group.length; i++) group[i].status = rootActivity.status;
                planboard.changeActivityGroup(rootActivity.id);
            }
        }

        // menu items
        var menuDivider = function () { return { enabled: true, divider: true }; }
        var menuActivityCut = {
            enabled: true, divider: false, text: "",
            onClick: function () {
                $scope.copyActivityId = planboard.selectedActivity.id;
                userService.setUserVariable("planboard.isCutAction", true);
            },
            initialize: function () {
                this.text = $scope.textLabels.MENU_CUT;
                return this;
            }
        }
        var menuActivityCopy = {
            enabled: true, divider: false, text: "",
            onClick: function () {
                $scope.copyActivityId = planboard.selectedActivity.id;
                userService.setUserVariable("planboard.isCutAction", false);
            },
            initialize: function () {
                this.text = $scope.textLabels.MENU_COPY;
                return this;
            }
        }
        var menuActivityPaste = {
            enabled: true, divider: false, text: "", pasteOnDate: null, resourceId: 0,
            onClick: function () {
                $scope.openActivityPasteView(this.pasteOnDate, this.resourceId);
            },
            initialize: function (pasteOnDate, resourceId) {
                this.text = $scope.textLabels.MENU_PASTE + "...";
                this.pasteOnDate = pasteOnDate;
                this.resourceId = resourceId;
                return this;
            }
        }
        var menuActivityProperties = {
            enabled: true, divider: false, text: "",
            onClick: function () { $scope.openActivityView(); },
            initialize: function () {
                this.text = $scope.textLabels.SHOW_ACTIVITY_PROPERTIES + "...";
                return this;
            }
        }
        var menuResourceManagement = {
            enabled: true, divider: false, text: "", resourceId: 0,
            onClick: function () {
                userService.setUserVariable("resources.selectResourceId", this.resourceId);
                $state.transitionTo("resourceManagement", { selectResourceId: this.resourceId });
            },
            initialize: function (resourceId) {
                this.text = $scope.textLabels.RESOURCE_MANAGEMENT_PAGE_TITLE + "...";
                this.resourceId = resourceId;
                return this;
            }
        }
        var menuSortByResourceProperty = {
            enabled: true, divider: false, text: "", colName: "", colId: -1,
            onClick: function () {
                // This is only possible when the feature flag FeaturePlanboardAFilterAndSorting_20230923 is off
                // Then we sort only by one column which is the first one in the planboard.sortByResourcePropertyIds 
                var currentSortPropertyId = planboard.sortByResourcePropertyIds.split(",")[0];

                if (currentSortPropertyId == this.colId.toString())
                    planboard.sortDescending = !planboard.sortDescending;
                else {
                    planboard.sortDescending = false;
                    planboard.sortByResourcePropertyIds = this.colId.toString();
                }
                $scope.saveUserDisplaySettings();
                $scope.refreshResourceList($scope.resourceSelectionId);
            },
            initialize: function (col) {
                this.colName = col == 0 ? $scope.textLabels.DISPLAY_NAME : $scope.textLabels.ORGANIZATION_UNIT;
                this.colId = col == 0 ? -1 : -2;
                var resPropCol = col - PlanboardResources.fixedColumns;
                if (resPropCol >= 0 && resPropCol < planboard.resourceProperties.length) {
                    this.colName = planboard.resourceProperties[resPropCol].text;
                    this.colId = planboard.resourceProperties[resPropCol].resourcePropertyId;
                }
                this.text = $scope.textLabels.SORT_BY + " " + this.colName;
                return this;
            }
        }
        var menuConfigureUnitFilter = {
            enabled: true, divider: false, text: "",
            onClick: function () {
                userService.setUserVariable("preferences.showUnitFilter", true);
            },
            transitionTo: "userPreferences",
            initialize: function () {
                this.text = $scope.textLabels.ORGANIZATION_UNIT_FILTER + "...";
                return this;
            }
        }
        var menuUserPreferences = {
            enabled: true, divider: false, text: "",
            onClick: function () {
                userService.setUserVariable("preferences.showResourceProperties", true);
            },
            transitionTo: "userPreferences",
            initialize: function () {
                this.text = $scope.textLabels.RESOURCE_PROPERTY_SHOW_IN_PLANBOARD + "...";
                return this;
            }
        }

        var reportActivityStatusChanged = function (activityId, response, title) {
            //var title = $scope.textLabels.ACTIVITY_SAVE_FAILED; // TODO: delete/cancel
            var failureReasons = planboard.getActivityFailureReasons(response && response.data ? response.data : response);
            planboard.readActivityGroup(activityId, true);

            commonSvc.showDialog(title,
                failureReasons,
                $scope.textLabels.OK,
                function () { });
        }

        var menuDeleteActivities = {
            enabled: true, divider: false, text: "", activityId: -1,
            onClick: function () {
                var deleteActivityId = this.activityId;
                commonSvc.showYesNoDialog($scope.textLabels.REMOVE_ACTIVITIES,
                    $scope.textLabels.DELETE_ACTIVITIES_CONFIRM,
                    function () {
                        if (planboard.multiSelectedActivities == null || planboard.multiSelectedActivities.count < 1) return;
                        // determine the root activities to delete
                        var selectedActivityIds = [];
                        var rootActivity = planboard.activities.getMainActivity(deleteActivityId);
                        if (rootActivity) selectedActivityIds.push(rootActivity.id);
                        planboard.multiSelectedActivities.forEach(function (id, item) {
                            rootActivity = planboard.activities.getMainActivity(id);
                            if (rootActivity && selectedActivityIds.indexOf(rootActivity.id) < 0)
                                selectedActivityIds.push(rootActivity.id);
                        });

                        // add a timed reload for all the activitygroups that were selected, in case not all could have been deleted
                        activityRefreshIsGroup = true;
                        refreshChangedActivities(selectedActivityIds);

                        planboard.deleteMultipleActivities(selectedActivityIds, commonSvc, $filter("date"), function () { }, function () { });
                    },
                    null);
            },
            initialize: function () {
                this.text = $scope.textLabels.REMOVE_ACTIVITIES;
                this.activityId = planboard.selectedActivity.id;
                return this;
            }
        }

        var menuDeleteActivity = {
            enabled: true, divider: false, text: "", activityId: -1,
            onClick: function () {
                var deleteActivityId = this.activityId;
                var rootActivity = planboard.activities.getMainActivity(deleteActivityId);
                if (featurePlanboardAPlanningBoard) {
                    var childActivities = planboard.getAllActivitiesInGroup(rootActivity.id);
                    var resourcesCount = 0;

                    for (var i = 0; i < childActivities.length; i++) {
                        var childActivity = childActivities[i];
                        if (childActivity.resourceId !== null && childActivity.resourceId !== undefined) {
                            resourcesCount++;
                        }
                    }

                    var confirmMessage;
                    if (resourcesCount > 1) {
                        confirmMessage = $scope.textLabels.DELETE_ACTIVITY_WITH_MULTIPLE_RESOURCES_CONFIRM;
                    } else {
                        confirmMessage = $scope.textLabels.DELETE_ACTIVITY_CONFIRM;
                    }

                    commonSvc.showYesNoDialog($scope.textLabels.DELETE_ACTIVITY, confirmMessage,
                        function () {
                            if (rootActivity) {
                                commonSvc.deleteData("api/Activities/" + rootActivity.id,
                                    function (response) {
                                        planboard.removeActivityGroupFromMemory(rootActivity.id, false);
                                        planboard.redrawAll();
                                    },
                                    function (response) { reportActivityStatusChanged(rootActivity.id, response, $scope.textLabels.ACTIVITY_DELETE_FAILED) },
                                    false);
                            }
                        },
                        null);
                } else {
                    commonSvc.showYesNoDialog($scope.textLabels.DELETE_ACTIVITY, $scope.textLabels.DELETE_ACTIVITY_CONFIRM,
                        function () {
                            if (rootActivity) {
                                commonSvc.deleteData("api/Activities/" + rootActivity.id,
                                    function (response) {
                                        planboard.removeActivityGroupFromMemory(rootActivity.id, false);
                                        planboard.redrawAll();
                                    },
                                    function (response) { reportActivityStatusChanged(rootActivity.id, response, $scope.textLabels.ACTIVITY_DELETE_FAILED) },
                                    false);
                            }
                        },
                        null);
                }
            },
            initialize: function () {
                this.text = $scope.textLabels.DELETE_ACTIVITY;
                this.activityId = planboard.selectedActivity.id;
                return this;
            }
        }

        var menuCancelActivity = {
            enabled: true, divider: false, text: "", activityId: -1,
            onClick: function () {
                var cancelActivityId = this.activityId;
                var rootActivity = planboard.activities.getMainActivity(cancelActivityId);
                controlIsDroppedDown[0] = true;

                if (featurePlanboardAPlanningBoard) {
                    var childActivities = planboard.getAllActivitiesInGroup(rootActivity.id);
                    var resourcesCount = 0;

                    for (var i = 0; i < childActivities.length; i++) {
                        var childActivity = childActivities[i];
                        if (childActivity.resourceId !== null && childActivity.resourceId !== undefined) {
                            resourcesCount++;
                        }
                    }

                    var confirmMessage;
                    if (resourcesCount > 1) {
                        confirmMessage = $scope.textLabels.CANCEL_ACTIVITY_CONFIRM_WITH_MULTIPLE_RESOURCES_CONFIRM;
                    } else {
                        confirmMessage = $scope.textLabels.CANCEL_ACTIVITY_CONFIRM;
                    }

                    commonSvc.showModalTextDialog($scope.textLabels.CANCEL_ACTIVITY, confirmMessage + "\n\n" + $scope.textLabels.CANCELLATION_REASON + ":",
                        [$scope.textLabels.YES, $scope.textLabels.NO],
                        [
                            function (cancelReason) {
                                controlIsDroppedDown[0] = false;
                                if (cancelReason == null || cancelReason.trim() === "") {
                                    commonSvc.showDialog($scope.textLabels.CANCEL_ACTIVITY, $scope.textLabels.ACTIVITY_SAVE_FAILED_31,
                                        $scope.textLabels.OK, function () { });
                                    return;
                                }
                            
                                if (rootActivity) {
                                    commonSvc.post("api/Activities/CancelActivity",
                                        {
                                            cancellationReason: cancelReason,
                                            rootActivityId: rootActivity.id
                                        },
                                        function (response) {
                                            planboard.removeActivityGroupFromMemory(rootActivity.id, false);
                                            planboard.redrawAll();
                                        },
                                        function (response) {
                                            reportActivityStatusChanged(rootActivity.id, response, $scope.textLabels.ACTIVITY_CANCEL_FAILED)
                                        },
                                        false);
                                }
                            },
                            function () {
                                controlIsDroppedDown[0] = false;
                            }
                        ],
                        "",
                        true);
                } else {
                    commonSvc.showModalTextDialog($scope.textLabels.CANCEL_ACTIVITY, $scope.textLabels.CANCEL_ACTIVITY_CONFIRM + "\n\n" + $scope.textLabels.CANCELLATION_REASON + ":",
                        [$scope.textLabels.YES, $scope.textLabels.NO],
                        [
                            function (cancelReason) {
                                controlIsDroppedDown[0] = false;
                                if (cancelReason == null || cancelReason.trim() === "") {
                                    commonSvc.showDialog($scope.textLabels.CANCEL_ACTIVITY, $scope.textLabels.ACTIVITY_SAVE_FAILED_31,
                                        $scope.textLabels.OK, function () { });
                                    return;
                                }
                                var rootActivity = planboard.activities.getMainActivity(cancelActivityId);
                                if (rootActivity)
                                    commonSvc.post("api/Activities/CancelActivity",
                                        {
                                            cancellationReason: cancelReason,
                                            rootActivityId: rootActivity.id
                                        },
                                        function (response) {
                                            planboard.removeActivityGroupFromMemory(rootActivity.id, false);
                                            planboard.redrawAll();
                                        },
                                        function (response) {
                                            reportActivityStatusChanged(rootActivity.id, response, $scope.textLabels.ACTIVITY_CANCEL_FAILED)
                                        },
                                        false);
                            },
                            function () {
                                controlIsDroppedDown[0] = false;
                            }
                        ],
                        "",
                        true);
                }
            },
            initialize: function () {
                this.text = $scope.textLabels.CANCEL_ACTIVITY;
                this.activityId = planboard.selectedActivity.id;
                return this;
            }
        };

        var menuLockUnlockActivity = {
            enabled: true, divider: false, text: "", activityId: -1,
            onClick: function () {
                commonSvc.loadData(urlGetResourceTypes,
                    resourceTypes,
                    (success) => {
                        var activityTypesWithoutWritePermissions =
                            planboard.getActivityTypesFromGroupWithoutWritePermissions(resourceTypes);

                        if (activityTypesWithoutWritePermissions.length === 0) {
                            updateLockStatusForActivityGroup(this.activityId);
                        } else {
                            var labelActTypesWithoutPermissions = "\n• " +
                                activityTypesWithoutWritePermissions.map(actType => actType.displayName)
                                    .join("\n• ");

                            modalConfirmationWindowService.showModalInfoDialog(
                                $scope.textLabels.ERROR_OCCURRED,
                                Globals.stringFormat("{0}\n{1}\n{2}",
                                    [
                                        $scope.textLabels.ACTIVITY_GROUP_LOCK_FAILED_REASON,
                                        labelActTypesWithoutPermissions,
                                        $scope.textLabels.ACTIVITY_GROUP_LOCK_FAILED_DESCRIPTION
                                    ]),
                                $scope.textLabels.OK,
                                null,
                                0,
                                dialogToken);
                        }
                    },
                    null,
                    true,
                    false);
            },
            initialize: function () {
                this.text = $scope.textLabels.LOCK;
                this.activityId = planboard.selectedActivity.id;
                var rootActivity = planboard.activities.getMainActivity(this.activityId);
                if (rootActivity && rootActivity.status == Constants.StatusActivityLocked)
                    this.text = $scope.textLabels.UNLOCK;
                else
                    this.text = $scope.textLabels.LOCK;
                return this;
            }
        }
        var menuFindReplacementResource = {
            enabled: true, divider: false, text: "",
            onClick: function () { if (this.enabled) $scope.openReplaceResourceView(); },
            initialize: function (isDaymark) {
                this.text = $scope.textLabels.FIND_REPLACEMENT_RESOURCE + "...";
                this.enabled = !isDaymark;
                return this;
            }
        }

        var menuUpgradeDaymark = {
            enabled: true,
            divider: false,
            text: "",
            onClick: function () { $scope.openUpgradeDaymarkView(); },
            initialize: function () {
                this.text = $scope.textLabels.UPGRADE_DAYMARK_MENU_OPTIONS;
                return this;
            }
        }

        // get the period used for checking for violations
        var getViolationsPeriod = function () {
            // first and last date in the planboard
            var firstDate = TimeSpan.fromDateNoTime(planboard.leftDate);
            var lastDate = new TimeSpan(firstDate.totalMiliseconds).addDays(planboard.areaMain.cols.count - 1);

            // today and 27 days later
            var currentDate = TimeSpan.today;
            var upToDate = new TimeSpan(currentDate.totalMiliseconds).addDays(27);

            // do not look before today and look at least 28 days after today
            if (firstDate.totalMiliseconds < currentDate.totalMiliseconds) firstDate = currentDate;
            if (upToDate.totalMiliseconds > lastDate.totalMiliseconds) lastDate = upToDate;

            // complete weeks starting at monday and ending at sunday, this depends on the feature flag useResourceViolationsCache
            if (useResourceViolationsCache) {
                var periodStartAdjustment = (firstDate.toDate().getDay() + 6) % 7;
                firstDate = periodStartAdjustment === 0 ? firstDate : firstDate.addDays(-periodStartAdjustment);
                var periodEndAdjustment = (lastDate.toDate().getDay() + 6) % 7;
                lastDate = periodEndAdjustment === 6 ? lastDate : lastDate.addDays(6 - periodEndAdjustment);
            }

            var violationsPeriod =
            {
                startDate: firstDate.toDate(),
                endDate: lastDate.toDate()
            };
            return violationsPeriod;
        }

        // overwrite the typescript planboard.onViolationIconClicked function
        Planboard.onViolationIconClicked = function (r) {
            var violationsPeriod = getViolationsPeriod();
            userService.setUserVariable("VIOLATIONS_START_DATE", violationsPeriod.startDate);
            userService.setUserVariable("VIOLATIONS_END_DATE", violationsPeriod.endDate);
            userService.setUserVariable("VIOLATIONS_RESOURCE_FILTER", r.displayName);
            $scope.openViolationsView();
        }

        // overwrite the typescript planboard.onMouseUpCell function
        Planboard.onMouseUpCell = function (areaControl, col, row, celX, celY, button, mouseX, mouseY) {
            if (button === 2) { // right mouse button
                var menuItems = [];
                var cellDate = null;
                var resourceId = 0;
                var menuCopyIndex = -1;
                var multiActivitySelection = planboard.multiSelectedActivities != null && planboard.multiSelectedActivities.count > 0;

                if (areaControl === planboard.areaMain || areaControl === planboard.areaToPlan || areaControl === planboard.areaDate) {
                    cellDate = new Date(planboard.leftDate.getTime());
                    cellDate.setHours(0, 0, 0, 0);
                    cellDate.setDate(cellDate.getDate() + col);
                }
                if (areaControl === planboard.areaMain || areaControl === planboard.areaResources || areaControl === planboard.areaCounters) {
                    resourceId = planboard.activities.getResourceId(row);
                }

                if ((areaControl === planboard.areaMain || areaControl === planboard.areaToPlan) &&
                    planboard.selectedActivity != null) {
                    var rootActivity = planboard.activities.getMainActivity(planboard.selectedActivity.id);
                    var permEditActivity = $scope.userHasPermission("EditActivity");
                    if (multiActivitySelection) {
                        // actions for multiple selected activities
                        if (permEditActivity)
                            menuItems.push(menuDeleteActivities.initialize());
                    } else {
                        // actions for a single selected activity
                        var permLockActivity = $scope.userHasPermission("LockActivity");
                        var permUnlockActivity = $scope.userHasPermission("UnlockActivity");
                        menuItems.push(menuActivityProperties.initialize());
                        if (permEditActivity && rootActivity) {
                            var rootActType = planboard.activityTypes.getObject(rootActivity.activityTypeId);
                            var rootActTypeIsDaymark = rootActType.categoryId === ActivityType.daymarkCategoryId;
                            if (!rootActTypeIsDaymark) {
                                if ((permUnlockActivity && rootActivity.status == Constants.StatusActivityLocked) ||
                                    (permLockActivity && rootActivity.status == Constants.StatusActivityPlanned))
                                    menuItems.push(menuLockUnlockActivity.initialize());
                            }
                            else {
                                menuItems.push(menuUpgradeDaymark.initialize());
                            }
                            if (!planboard.isAnyActivityFromGroupLocked(rootActivity.id)) {
                                menuItems.push(menuDivider());
                                if ($scope.userHasPermission("CancelActivities"))
                                    menuItems.push(menuCancelActivity.initialize());
                                menuItems.push(menuDeleteActivity.initialize());
                            }
                            menuItems.push(menuDivider());
                            if (permEditActivity && !planboard.isAnyActivityFromGroupLocked(rootActivity.status))
                                menuItems.push(menuActivityCut.initialize());
                            menuItems.push(menuActivityCopy.initialize());
                            menuCopyIndex = menuItems.length;
                            menuItems.push(menuDivider());
                            menuItems.push(menuFindReplacementResource.initialize(rootActTypeIsDaymark));
                        }
                    }
                }
                if ((areaControl === planboard.areaMain || areaControl === planboard.areaToPlan) &&
                    $scope.copyActivityId != null && $scope.copyActivityId >= 0) {
                    var rootActivity = planboard.activities.getMainActivity($scope.copyActivityId);
                    if (!rootActivity)
                        $scope.copyActivityId = -1;
                    else if (!multiActivitySelection) {
                        var menuItemPaste = menuActivityPaste.initialize(cellDate, resourceId);
                        if (menuCopyIndex >= 0)
                            menuItems.splice(menuCopyIndex, 0, menuItemPaste);
                        else
                            menuItems.push(menuItemPaste);
                    }
                }

                if (areaControl === planboard.areaResources) {
                    menuItems.push(menuResourceManagement.initialize(resourceId));
                }

                // remove excess dividers
                for (var i = menuItems.length - 1; i >= 0; i--)
                    if (menuItems[i].divider)
                        if (i === 0 || i === menuItems.length - 1 || menuItems[i - 1].divider || menuItems[i + 1].divider)
                            menuItems.splice(i, 1);

                $scope.popupMenuItems = menuItems;
                var screenX = mouseX + areaControl.screenPos.left + mainCanvasDiv.offset().left;
                var screenY = mouseY + areaControl.screenPos.top + mainCanvasDiv.offset().top;
                showPopupMenu(screenX, screenY);
            } else
                hidePopupMenu(true);
        }

        // overwrite the typescript planboard.onMouseDownCell function
        Planboard.onMouseDownCell = function (areaControl, col, row, celX, celY, button, mouseX, mouseY) {
            hidePopupMenu(false);
        }

        // overwrite the typescript WebApi.post function
        WebApi.post = function (url, postData, action, errorAction) {
            incrementWebApiCalls();
            userService.setLogoffWaitTime(dialogToken, automaticSaveDelay);
            $http.post(url, postData)
                .then(function (response) {
                    decrementWebApiCalls();
                    if (response.data != undefined)
                        action(response.data);
                    else
                        errorAction(response);
                    userService.setLogoffWaitTime(dialogToken, 0);
                },
                    function (response) {
                        decrementWebApiCalls();
                        errorAction(response.data != undefined ? response.data : response);
                        userService.setLogoffWaitTime(dialogToken, 0);
                    });
        }

        // overwrite the typescript WebApi.get function
        WebApi.get = function (url, action, errorAction) {
            incrementWebApiCalls();
            $http.get(url)
                .then(function (response) {
                    decrementWebApiCalls();
                    if (response.data != undefined)
                        action(response.data);
                    else
                        errorAction(response);
                },
                    function (response) {
                        decrementWebApiCalls();
                        errorAction(response.data != undefined ? response.data : response);
                    });
        }

        // overwrite the typescript WebApi.delete function
        WebApi.delete = function (url, action, errorAction) {
            incrementWebApiCalls();
            $http.delete(url)
                .then(function (response) {
                    decrementWebApiCalls();
                    if (response.data != undefined)
                        action(response.data);
                    else
                        errorAction(response);
                },
                    function (response) {
                        decrementWebApiCalls();
                        errorAction(response.data != undefined ? response.data : response);
                    });
        }

        // slide in and slide out the button bar on mouseover and mouseout
        buttonBarDiv.mouseover(showButtonBar).mouseout(hideButtonBar);

        // start resize event handler
        $(window).on("resize.doResizePlanboard", doResizePlanboard);

        // save the user display settings for various variables
        $scope.saveUserDisplaySettings = function () {
            planboard.assignUserDisplayPreferences();
            userService.setDisplaySetting("planboard.sortByResourcePropertyId", planboard.sortByResourcePropertyIds);
            userService.setDisplaySettingSwitch("planboard.sortDescending", planboard.sortDescending);
            userService.setDisplaySettingSwitch("planboard.applyUnitFilterToResources", planboard.applyUnitFilterToResources)
            userService.setDisplaySettingSwitch("planboard.applyResourcesToHide", planboard.applyResourcesToHide);
            userService.setDisplaySettingSwitch("planboard.applySkillsFilter", planboard.applySkillsFilter);
            userService.setDisplaySettingSwitch("planboard.applyResourceFilterInPlanboard", planboard.applyCustomResourceFilter);
            userService.setDisplaySettingNumber("planboard.selectedActTypeId", planboard.selectedActivityTypeId);
            userService.setDisplaySettingNumber("planboard.zoomLevel", planboard.zoomLevel);
            userService.setDisplaySettingNumber("planboard.splitResourceWidth", planSplitter.splitResourceWidth);
            userService.setDisplaySettingNumber("planboard.splitUnplannedHeight", planSplitter.splitUnplannedHeight);
            userService.setDisplaySettingNumber("planboard.splitCountersWidth", planSplitter.splitCountersWidth);
            userService.setDisplaySettingSwitch("planboard.buttonBarPinned", $scope.buttonBarPinned);
            if (featurePlanboardAPlanningBoard) {
                userService.setDisplaySettingNumber("planboard.lineHeight", $scope.lineHeightValue);
            }
            if (planboard.resourceProperties != null && planboard.areaResources != null) {
                var colWidth = planboard.areaResources.cols.getSize(0); // displayname column width
                userService.setDisplaySettingNumber("planboard.resourceColWidth", colWidth);
                colWidth = planboard.areaResources.cols.getSize(1); // organization unit column width
                userService.setDisplaySettingNumber("planboard.unitColWidth", colWidth);
                var fixedCols = PlanboardResources.fixedColumns;
                for (var i = 0; i < planboard.resourceProperties.length; i++) {
                    colWidth = planboard.areaResources.cols.getSize(i + fixedCols); // other user defined column widths
                    userService.setDisplaySettingNumber("planboard.resourceColWidth" + planboard.resourceProperties[i].resourcePropertyId.toString(), colWidth);
                }
            }
            if (planboard.areaCounters.visible)
                for (var i = 0; i < planboard.areaCounters.cols.count; i++)
                    userService.setDisplaySettingNumber("planboard.countersColWidth" + i.toString(), planboard.areaCounters.cols.getSize(i));
        }

        // remove resize event handler
        $scope.$on("$destroy",
            function () {
                $scope.saveUserDisplaySettings();
                unregisterPlanboardEvents();
                unregisterBatchActivityMessagesHandler();
                $(window).off("resize.doResizePlanboard");
                // re-enable body scrollbars
                document.body.style.overflow = "auto"; // firefox, chrome
                document.body.scroll = "yes"; // ie only

                supendOnIdleTimeDestroyed = true;
                removeIdleUserEventListeners();
            });

        // refresh or initial load of resource properties
        $scope.refreshResourceProperties = function () {
            commonSvc.loadData("api/ResourceProperties/" + encodeURIComponent(translationService.getCurrentLanguage() + "/ForUser"), null,
                function (response, loadInto) {
                    planboard.resourceProperties = loadInto;
                    if (planboard.resourceProperties != null && planboard.areaResources != null) {
                        var id = -1; // displayname column width
                        var colWidth = userService.getDisplaySettingNumber("planboard.resourceColWidth", 0);
                        if (colWidth > 0) PlanboardResources.initializedColumnSizes.add(id, colWidth);
                        id = -2; // organization unit column width
                        colWidth = userService.getDisplaySettingNumber("planboard.unitColWidth", 0);
                        if (colWidth > 0) PlanboardResources.initializedColumnSizes.add(id, colWidth);
                        // other user defined column widths
                        for (var i = 0; i < planboard.resourceProperties.length; i++) {
                            id = planboard.resourceProperties[i].resourcePropertyId;
                            colWidth = userService.getDisplaySettingNumber("planboard.resourceColWidth" + id.toString(), 0);
                            if (colWidth > 0) PlanboardResources.initializedColumnSizes.add(id, colWidth);
                        }
                    }
                    planboard.updateResourcePropertyColumns();
                },
                null, true, false);
        }

        // load data for the planboard and buttonbar
        var loadData = function () {
            // initialize menus
            initializeMenuBarItems();

            // clear previous dictionaries ($scope.resourceSelections will be cleared when receiving data)
            $scope.actTypes.clear();

            // add the no selection activity type
            $scope.actTypes.add(dropdownActivityTypesNoneId, { displayName: $scope.textLabels.FILTER_NONE, id: dropdownActivityTypesNoneId, order: -Globals.maxInt + 2 });
            categoriesIds.push(dropdownActivityTypesNoneId);

            // load resource selections (this is a list of all readable resource types)
            commonSvc.loadData("api/ResourceTypes/ForPlanningBoard", $scope.resourceSelections, function () {
                planboard.resourceTypes = $scope.resourceSelections;
                $scope.resourceSelectionsLoaded = true;
                // apply the filter if activity types are finished loading
                if (!planboard.activityTypesLoading) {
                    applyActivityTypeFilter();
                    planboard.timedRefresh();
                }
            }, null, true, true);

            // load user-selected activity type ids and apply to activity type filter
            if (planboard.userFilterActivityTypeIds == null) // only load if the filter is not yet known
                commonSvc.loadData("api/Users/SelectedActivityTypes", null, function (response, loadInto) {
                    // remember the list of root activitytype ids
                    planboard.userFilterActivityTypeIds = loadInto;
                    // apply the filter if activity types are finished loading and resource selections are finished loading
                    if (!planboard.activityTypesLoading && $scope.resourceSelectionsLoaded) {
                        applyActivityTypeFilter();
                        planboard.timedRefresh();
                    }
                }, null, true, true);

            // load resource property names
            $scope.refreshResourceProperties();

            // load activitytype categories
            commonSvc.loadData("api/ActivityTypes/Categories", null,
                function (response, loadInto) {
                    var categoriesOrdered = $filter("orderBy")(loadInto, "-displayName");

                    for (var i = 0; i < categoriesOrdered.length; i++) {
                        categoriesOrdered[i].displayName = $scope.textLabels[categoriesOrdered[i].jsonName];
                        categoriesOrdered[i].order = -i; // caution, this overrides any order the server may have set
                        categoriesOrdered[i].id += Globals.maxInt;
                        $scope.actTypes.add(categoriesOrdered[i].id, categoriesOrdered[i]);
                        categoriesIds.push(categoriesOrdered[i].id);
                    }
                },
                null, true, false);

            // load open leave requests
            commonSvc.loadData("api/LeaveRequests/TotalsOpen", null,
                function (response, loadInto) {
                    $scope.openLeaveRequests = loadInto;
                },
                null, true, false);

            // load main activitytypes
            commonSvc.loadData("api/ActivityTypes/MainActivityTypes/WithUserGroupWritePermissions", null,
                function (response, loadInto) {
                    var activityTypesSorted = loadInto;

                    var selectedActTypeFound = false;

                    for (var i = 0; i < activityTypesSorted.length; i++) {

                        if (activityTypesSorted[i].validTo &&
                            Timezone.correctTimeZoneInfo(new Date()) >
                            Timezone.correctTimeZoneInfo(activityTypesSorted[i].validTo)) {

                            continue;
                        }

                        selectedActTypeFound |= activityTypesSorted[i].id === $scope.selectedActTypeId;

                        activityTypesSorted[i].order = i; // caution, this overrides any order the server may have set
                        var categoryId = activityTypesSorted[i].categoryId + Globals.maxInt;
                        if (!$scope.actTypes.value(categoryId))
                            $scope.actTypes.add(categoryId, { id: categoryId, displayName: "" + categoryId });
                        activityTypesSorted[i].parentId = categoryId;
                        if (activityTypesSorted[i].maxPermissionForCurrentUser >= 2)
                            $scope.actTypes.add(activityTypesSorted[i].id, activityTypesSorted[i]);
                    }

                    // Reset the selected activity type when we did not find it in the filtered list from the server.
                    if (!selectedActTypeFound) {
                        planboard.selectedActivityTypeId = -1;
                        $scope.selectedActTypeId = dropdownActivityTypesNoneId;
                    }
                },
                null, true, false);

            loadScenarios();

            // set the planboard on read only if the user does not have the EditActivity permission.
            pageStartService.userHasPermission("EditActivity", $scope,
                function (result) {
                    if (!result) planboard.readOnly = true;
                });

            pageStartService.userHasPermission("Planboard",
                $scope,
                function (result) {
                    if (!result) {
                        userService.isFullUser ? $state.transitionTo("portal") : $state.transitionTo("reports");
                    }
                });

            $scope.loadPlanningStatus();

            // set current state name and text
            userService.setUserVariable("preferences.breadcrumbState", "planboard");
            userService.setUserVariable("preferences.breadcrumbText", $scope.textLabels.PLANBOARD_PAGE_TITLE);

            // remove old activity changes
            if (!planboard.activityChangesDeleted) {
                planboard.activityChangesDeleted = true;
                $timeout(function () {
                    if (userService && userService.isAuthenticated)
                        commonSvc.post("api/Activities/RemoveChanges", null, null, null, false);
                }, planningStatusDelay * 2); // give other webapi calls time to complete
            }
        }

        // refresh (or initial load) of violations for specific resources
        $scope.refreshViolationsUncached = function (resourceIdList) {
            var violationsPeriod = getViolationsPeriod();
            var violationSpec =
            {
                periodStart: violationsPeriod.startDate,
                periodEnd: violationsPeriod.endDate,
                scenarioId: $scope.scenarioId,
                resourceIds: resourceIdList
            };
            commonSvc.post("api/Violations/Count", violationSpec,
                function (response) {
                    var anyChanges = false;

                    // first clear any violations for the requested resources
                    for (var i = 0; i < resourceIdList.length; i++)
                        if (planboard.activities.getResourceViolations(resourceIdList[i]) > 0) {
                            planboard.activities.setResourceViolations(resourceIdList[i], 0);
                            anyChanges = true;
                        }

                    // set number of violations for resources that have violations
                    if (response.data)
                        for (var resourceId in response.data) {
                            planboard.activities.setResourceViolations(Number(resourceId), response.data[resourceId].count);
                            anyChanges = true;
                        }

                    if (anyChanges) planboard.timedRefresh();
                },
                null, false);
        }

        // count violation numbers in period countStartDate to countEndDate
        $scope.countResourceViolations = function (resourceIds, countStartDate, countEndDate) {
            var anyChanges = false;

            for (var i = 0; i < resourceIds.length; i++) {
                var resourceId = resourceIds[i];
                // $scope.cachedResourceViolations is a dictionary of resources, the key is the id of the resource, the value is a dictonary of weeks
                // cachedWeeks is a dictionary of weeks for each resource, the key is the date of the week in total miliseconds, the value is the number of violations in that week
                var cachedWeeks = $scope.cachedResourceViolations.value(resourceId);
                var nrViolations = 0;
                var startWeekDate = TimeSpan.fromDateNoTime(countStartDate);
                var endWeekDate = TimeSpan.fromDateNoTime(countEndDate);
                while (startWeekDate.totalMiliseconds < endWeekDate.totalMiliseconds) {
                    if (cachedWeeks != null && cachedWeeks.value(startWeekDate.totalMiliseconds) != null) {
                        nrViolations += cachedWeeks.value(startWeekDate.totalMiliseconds);
                    }
                    startWeekDate = startWeekDate.addDays(7);
                }

                if (planboard.activities.getResourceViolations(resourceId) !== nrViolations) {
                    planboard.activities.setResourceViolations(resourceId, nrViolations);
                    anyChanges = true;
                }
            }

            if (anyChanges) {
                planboard.timedRefresh();
            }
        }

        // load resource violations and count the violations in the period countStartDate to countEndDate
        $scope.loadResourceViolations = function (resourceIds, loadStartDate, loadEndDate, countStartDate, countEndDate) {
            var violationSpec =
            {
                periodStart: planboard.dateToStr(loadStartDate),
                periodEnd: planboard.dateToStr(loadEndDate),
                scenarioId: $scope.scenarioId,
                resourceIds: resourceIds
            };

            commonSvc.post("api/Violations/Count", violationSpec,
                function (response) {
                    // Initialize cache for each requested resource if necessary and clear the values for requested weeks.
                    for (var i = 0; i < resourceIds.length; i++) {
                        var resourceId = resourceIds[i];
                        var cachedWeeks = $scope.cachedResourceViolations.value(resourceId);
                        if (cachedWeeks == null) {
                            cachedWeeks = new Dictionary();
                            $scope.cachedResourceViolations.add(resourceId, cachedWeeks);
                        }
                        var startWeekDate = TimeSpan.fromDateNoTime(loadStartDate);
                        var endWeekDate = TimeSpan.fromDateNoTime(loadEndDate);
                        while (startWeekDate.totalMiliseconds < endWeekDate.totalMiliseconds) {
                            cachedWeeks.add(startWeekDate.totalMiliseconds, 0);
                            startWeekDate = startWeekDate.addDays(7);
                        }
                    }

                    // Update cache with received data.
                    if (response.data) {
                        for (var resourceIdValue in response.data) {
                            var resourceId = Number(resourceIdValue);
                            var cachedWeeks = $scope.cachedResourceViolations.value(resourceId);
                            if (cachedWeeks == null) {
                                continue;
                            }
                            var countPerWeek = response.data[resourceId].countPerWeek;
                            for (var week in countPerWeek) {
                                var parts = week.split('-');
                                var weekDate = TimeSpan.fromDateNoTime(new Date(parts[0], parts[1] - 1, parts[2]));
                                cachedWeeks.add(weekDate.totalMiliseconds, countPerWeek[week]);
                            }
                        }
                    }

                    // Count violation numbers in period countStartDate to countEndDate.
                    $scope.countResourceViolations(resourceIds, countStartDate, countEndDate);
                },
                null, false);
        }

        // refresh (or initial load) of violations for specific resources
        $scope.refreshViolations = function (resourceIdList) {
            // if the feature flag useResourceViolationsCache is false then we call the old function instead.
            if (!useResourceViolationsCache) {
                $scope.refreshViolationsUncached(resourceIdList);
                return;
            }

            var violationsPeriod = getViolationsPeriod();

            var minWeekToLoad = null;
            var maxWeekToLoad = null;
            var cachedResourceIds = [];
            var notCachedResourceIds = [];

            // For each resource Id see if we have cached the violations per week.
            for (var i = 0; i < resourceIdList.length; i++) {
                var resourceId = resourceIdList[i];
                if ($scope.cachedResourceViolations.containsKey(resourceId)) {
                    // This resource has cached violations. Test what weeks are known.
                    cachedResourceIds.push(resourceId);
                    var cachedWeeks = $scope.cachedResourceViolations.value(resourceId);
                    var startWeekDate = TimeSpan.fromDateNoTime(violationsPeriod.startDate);
                    var endWeekDate = TimeSpan.fromDateNoTime(violationsPeriod.endDate);
                    while (startWeekDate.totalMiliseconds < endWeekDate.totalMiliseconds) {
                        if (!cachedWeeks.containsKey(startWeekDate.totalMiliseconds)) {
                            if (minWeekToLoad == null || startWeekDate.totalMiliseconds < minWeekToLoad.totalMiliseconds) {
                                minWeekToLoad = new TimeSpan(startWeekDate.totalMiliseconds);
                            }
                            if (maxWeekToLoad == null || startWeekDate.totalMiliseconds > maxWeekToLoad.totalMiliseconds) {
                                maxWeekToLoad = new TimeSpan(startWeekDate.totalMiliseconds);
                            }
                        }
                        startWeekDate = startWeekDate.addDays(7);
                    }
                }
                else {
                    // This resource does not yet have any cached violations.
                    notCachedResourceIds.push(resourceId);
                }
            }

            // Load violations for uncached weeks for resources that were already cached.
            if (cachedResourceIds.length > 0 && minWeekToLoad != null && maxWeekToLoad != null) {
                console.log("Violations: updating for cached resources.");
                $scope.loadResourceViolations(
                    cachedResourceIds,
                    minWeekToLoad.toDate(),
                    maxWeekToLoad.addDays(6).toDate(),
                    violationsPeriod.startDate,
                    violationsPeriod.endDate);
            }
            else if (cachedResourceIds.length > 0) {
                // Nothing to load, everything is already in cache.
                console.log("Violations: counting from cache.");
                $scope.countResourceViolations(cachedResourceIds, violationsPeriod.startDate, violationsPeriod.endDate);
            }

            // Load violations for resources that were not cached before.
            if (notCachedResourceIds.length > 0) {
                console.log("Violations: loading for uncached resources.");
                $scope.loadResourceViolations(
                    notCachedResourceIds,
                    violationsPeriod.startDate,
                    violationsPeriod.endDate,
                    violationsPeriod.startDate,
                    violationsPeriod.endDate);
            }
        }

        // refresh (or initial load) of resources in a selection
        $scope.refreshResourceList = function (selectionId, modifiedResourceIds) {
            var lastDate = new Date(planboard.leftDate.getTime());
            lastDate.setDate(lastDate.getDate() + planboard.areaMain.cols.count - 1);

            lastResourceFilter =
            {
                resourceTypeId: selectionId,
                referenceDate: planboard.dateToStr(planboard.leftDate),
                beforeReferenceDate: planboard.dateToStr(lastDate),
                sortByResourcePropertyIds: planboard.sortByResourcePropertyIds.split(','),
                sortDescending: planboard.sortDescending,
                applyUnitFilter: planboard.applyUnitFilterToResources,
                applyResourceFilter: planboard.applyCustomResourceFilter,
                applyResourcesToHide: planboard.applyResourcesToHide,
                applySkillsFilter: planboard.applySkillsFilter
            };

            commonSvc.post("api/Resources/ForPlanningBoard/Filtered", lastResourceFilter,
                function (response) {
                    // create a list of resource ids
                    var loadInto = response.data;
                    var resourceIdList = [];
                    for (var i = 0; i < loadInto.length; i++) resourceIdList.push(loadInto[i].id);
                    // is there a difference?
                    if (!planboard.activities.isIdenticalResourceSelection(selectionId, resourceIdList)) {
                        // make sure these resources exist in the planboard
                        planboard.addResources(loadInto);
                        // set or update this selection
                        planboard.activities.setResourceIdsInSelection(selectionId, resourceIdList);
                    } else {
                        // make sure these resources exist in the planboard
                        planboard.addResources(loadInto);
                    }
                    // show current selection
                    planboard.activities.activateResourceSelection(selectionId, true);

                    // ask webapi if there are any violations for the received resources
                    $scope.refreshViolations(resourceIdList);

                    if (modifiedResourceIds != null) {
                        planboard.readResourceUnits(modifiedResourceIds);
                        planboard.timedRefresh();
                    }
                },
                null, false);
        }

        // load configuration settings
        var loadConfigurationSettings = function () {
            commonSvc.loadData("api/Configuration/GetPlanboardSpinnerDelayTime", null,
                function (response) {
                    webApiCallsWaitDelay = Number(response.data);
                    if (isNaN(webApiCallsWaitDelay)) {
                        webApiCallsWaitDelay = 500;
                    }
                },
                null, false, false);
        }

        // subscribe to websocket events
        var subscribeToWebSocketEvents = function () {

            pageStartService.subscribeToWebSocketEvent($scope, "activityEvent", captureBatchActivityMessages);
            pageStartService.subscribeToWebSocketEvent($scope, "activityTypeEvent", activityTypeEvent);
            pageStartService.subscribeToWebSocketEvent($scope, "resourceEvent", resourceEvent);
            pageStartService.subscribeToWebSocketEvent($scope, "scenarioEvent", scenarioEvent);
            pageStartService.subscribeToWebSocketEvent($scope, "userEvent", userEvent);
        }

        configurationService.getAppConfiguration(function () {
            initializeUserSettings();

            useResourceViolationsCache =
                configurationService.appConfiguration.featureManagement.performanceImprovementsJune2021;

            clearSelectedActivityFromPalette = true;
            clearCurrentActivityTypeSelection();

            if (!configurationService.appConfiguration.featureManagement.featurePlanboardAPlanningBoard) {
                $scope.lineHeightMenu.remove(4);
		    }

            if (configurationService.appConfiguration.featureManagement.performanceImprovementsJune2021) {
                var batchBroadcastCooldown =
                    configurationService.appConfiguration.planboardSettings.batchBroadcastCooldown;
                // register batch activity messages handler and start polling
                registerBatchActivityMessagesHandler(batchBroadcastCooldown);
            }

            if (configurationService.appConfiguration.featureManagement.featurePlanboardAPlanningBoard) {
                featurePlanboardAPlanningBoard = true;
                $scope.addModeButtonVisible = featurePlanboardAPlanningBoard;
                $scope.lineHeightValue = userService.getDisplaySettingNumber("planboard.lineHeight", $scope.lineHeightValue);
                Planboard.autoRowHeight = $scope.lineHeightValue == 4;
            }

            if (configurationService.appConfiguration.featureManagement.featureUseActivityRequestHandling) {
                featureUseActivityRequestHandling = true;
            }

            if (configurationService.appConfiguration.environmentSettings) {
                $scope.isNonProductionEnvironment = String(configurationService.appConfiguration.environmentSettings.isNonProductionEnvironment).toLowerCase() === "true";
            }

            suspendPlanboardUpdatesWhenIdle = configurationService.appConfiguration.featureManagement
                .suspendPlanboardUpdatesWhenIdle.featureState;

            inactivityTimeDelay = configurationService.appConfiguration.featureManagement
                .suspendPlanboardUpdatesWhenIdle.idleTime;

            // load resource selections (this is a list of all readable resource types)
            commonSvc.loadData("api/Users/SelectedActivityTypesFilterAndSorting", null, function (response) {
                $scope.userFilterAndSorting = response;
            }, null, true, true);

            handleInactivityTime();

            subscribeToWebSocketEvents();

            setScenarioColor();
        },
        function() {
            subscribeToWebSocketEvents();
        });

        function initializePlanboardWithLeftDate(currentLeftDate) {
            if (currentLeftDate != undefined && planboard != undefined && planboard.areaMain != undefined) {
                const dateObject = new Date(currentLeftDate);
                $scope.startDate = dateObject;
                planboard.setCurrentDate(dateObject, true);
            }
        }

        // initialization
        $timeout(function () {
            saveCurrentDate = false;
            // disable body scrollbars
            document.body.style.overflow = "hidden"; // firefox, chrome
            document.body.scroll = "no"; // ie only

            // overwrite the empty typescript readViolations function
            Planboard.readViolations = $scope.refreshViolations;

            // planboard initialization
            registerPlanboardEvents();
            Initialization.initializeAll("mainCanvasDiv");

            // overwrite the typescript onScroll function
            MainController.getcontroller("mainCanvasDiv").onScrollStart = function () {
                hidePopupMenu(true);
            }

            // overwrite the typescript onDrawEnd function
            MainController.getcontroller("mainCanvasDiv").onDrawEnd = function () {
                if (lastPlanAreaInnerLeft === planboard.areaMain.innerLeft) return;
                var colNr = planboard.areaMain.cols.nrAtPos(planboard.areaMain.innerLeft);
                if (lastPlanAreaColNr === colNr) return;
                lastPlanAreaInnerLeft = planboard.areaMain.innerLeft;
                lastPlanAreaColNr = colNr;
                $scope.updateCounters();
            }

            // overwrite the typescript doubleclick events (must be done after OMRP.Entities.initializeAll)
            Planboard.areaMain.mouseDblClick = function (x, y, button) {
                $scope.openActivityView();
            }
            Planboard.areaToPlan.mouseDblClick = Planboard.areaMain.mouseDblClick;

            // configuration settings
            loadConfigurationSettings();

            // begin with a resize
            doResizePlanboard();

            //go to the current date in planboard
            initializePlanboardWithLeftDate($scope.currentLeftDatePlanboard);

            // initial load data
            loadData();
        }, 0);

        // get the display text that describes week number, e.g. "week"
        $scope.getWeekNrText = function () {
            return $scope.textLabels.WEEK.toLowerCase();
        }

        // get the display text that describes going to today
        $scope.getGoToTodayText = function () {
            return $scope.textLabels.TO_TODAY;
        }

        // click on menu item in popup menu
        $scope.menuItemClick = function (menuItem) {
            hidePopupMenu(true);
            if (!menuItem) return;
            if (menuItem.onClick) menuItem.onClick();
            if (menuItem.transitionTo) $state.transitionTo(menuItem.transitionTo, {});
        }

        // keydown on planboard view
        $scope.keyDownEvent = function (evt) {
            if (!planboard.controller.enabled) return;
            for (var i = 0; i < controlIsDroppedDown.length; i++) if (controlIsDroppedDown[i]) return;

            var code = evt.code && evt.code > 0 ? evt.code :
                evt.keyCode && evt.keyCode > 0 ? evt.keyCode :
                    evt.which && evt.which > 0 ? evt.which : 0;
            if (code === 0) {
                if (evt.key === "Left" || evt.key === "ArrowLeft") code = 37;
                else if (evt.key === "Up" || evt.key === "ArrowUp") code = 38;
                else if (evt.key === "Right" || evt.key === "ArrowRight") code = 39;
                else if (evt.key === "Down" || evt.key === "ArrowDown") code = 40;
                else if (evt.key === "Del" || evt.key === "Delete") code = 46;
            }

            // find if the mouse is in the areaMain (default) or in areaToPlan
            var areaMain = planboard && planboard.areaMain ? planboard.areaMain : null; // areaMain exists
            if (areaMain && planboard.controller.mouseOverControl === planboard.areaToPlan) areaMain = planboard.areaToPlan; // in areaToPlan
            if (areaMain && planboard.controller.mouseOverControl === planboard.areaToPlan.vBar) areaMain = planboard.areaToPlan; // in areaToPlan's vertical scrollbar

            if (code === 37) $scope.scrollDays(-1); // left
            if (code === 39) $scope.scrollDays(1); // right
            if (code === 38 && areaMain) areaMain.vBar.setValue(areaMain.snapPositionToGrid(areaMain.vBar.value - 1, 1, false, true)); // up
            if (code === 40 && areaMain) areaMain.vBar.setValue(areaMain.snapPositionToGrid(areaMain.vBar.value + 1, 2, false, true)); // down

            if (code === 46 && (!evt.altKey || !evt.ctrlKey)) { // delete (without both ctrl key + alt key pressed)
                if (planboard.multiSelectedActivities != null && planboard.multiSelectedActivities.count > 0) {
                    menuDeleteActivities.initialize().onClick();
                } else if (planboard.selectedActivity) {
                    var rootActivity = planboard.activities.getMainActivity(planboard.selectedActivity.id);
                    if (rootActivity && rootActivity.status != Constants.StatusActivityLocked)
                        menuDeleteActivity.initialize().onClick();
                };
            }
        }

        // return if the current user has a specific permission
        $scope.userHasPermission = function (permissionName) {
            return pageStartService.userHasPermission(permissionName, $scope, null);
        }

        // transition to a state if the user has a specific permission
        $scope.transitionWithPermission = function (permissionName, stateName, stateParams) {
            pageStartService.userHasPermission(permissionName, $scope,
                function (result) {
                    if (result) $state.transitionTo(stateName, stateParams);
                });
        }

        // show the solver management view
        $scope.showSolverManagement = function () {
            clearCurrentActivityTypeSelection();
            $scope.transitionWithPermission("SolverManagement", "solverManagement", {});
        }

        // show the multiselect view
        $scope.showMultiSelect = function () {
            clearCurrentActivityTypeSelection();
            $scope.transitionWithPermission("MultiSelect", "multiSelect", {});
        }

        // returns if a child view is visible and disables the planboard if a child view is visible
        // returns "hidden" if the state is "planboard" or "visible" if the state is anything else, for example "planboard.activity"
        $scope.getChildViewVisible = function () {
            var visible = $state.$current.name !== "planboard";
            if (planboard.controller != null) planboard.controller.setEnabled(!visible);
            return visible ? "visible" : "hidden";
        }

        // show the activities paste view
        $scope.openActivityPasteView = function (pasteOnDate, pasteAtResourceId) {
            if ($scope.copyActivityId != null && $scope.copyActivityId >= 0) {
                var id = $scope.copyActivityId;
                if (id > 0) {
                    ActivityInfo.hide();
                    $state.transitionTo("planboard.activityPaste", { activityId: id, pasteDate: pasteOnDate, resourceId: pasteAtResourceId });
                }
            }
        }

        // show the activities view
        $scope.openActivityView = function () {
            if (planboard.selectedActivity != null) {
                var id = planboard.selectedActivity.id;
                if (id > 0) { // only for activities that are already saved (otherwise the transitionTo state is also invalid with a negative number)
                    ActivityInfo.hide();
                    $state.transitionTo("planboard.activity", { activityId: id });
                }
            }
        }

        // show the replace resource view
        $scope.openReplaceResourceView = function () {
            if (planboard.selectedActivity != null) {
                var id = planboard.selectedActivity.id;
                if (id > 0) { // only for activities that are already saved (otherwise the transitionTo state is also invalid with a negative number)
                    ActivityInfo.hide();
                    $state.transitionTo("planboard.replaceResource", { activityId: id });
                }
            }
        }

        // show the upgrade daymark view
        $scope.openUpgradeDaymarkView = function () {
            if (planboard.selectedActivity != null) {
                var id = planboard.selectedActivity.parentId !== -1 ? planboard.selectedActivity.parentId : planboard.selectedActivity.id;
                if (id > 0) { // only for activities that are already saved (otherwise the transitionTo state is also invalid with a negative number)
                    ActivityInfo.hide();
                    $state.transitionTo("planboard.upgradeDaymark", { activityId: id });
                }
            }
        }


        // show the custom counter period selector view
        $scope.openCustomCounterPeriodView = function () {
            clearCurrentActivityTypeSelection();
            $state.transitionTo("planboard.customCounterPeriod");
        }

        // show the scenario conflicts view
        $scope.openScenarioConflictsView = function () {
            clearCurrentActivityTypeSelection();
            $state.transitionTo("planboard.mergeConflicts");
        }

        // show the changes in planning view
        $scope.openChangesInPlanningView = function () {
            clearCurrentActivityTypeSelection();
            $window.location.href = "/angularomrp/planboard/changes";
        }

        //redirect to preference screen
        $scope.openPreferences = function () {
            $state.transitionTo("userPreferences");
        }

        // show the violations view
        $scope.openViolationsView = function () {
            clearCurrentActivityTypeSelection();
            $state.transitionTo("planboard.violations");
        }

        // show the planning status view
        $scope.openPlanningStatusView = function () {
            clearCurrentActivityTypeSelection();
            $state.transitionTo("planboard.planningStatus");
        }

        //opens a new window to Team Scheduler
        $scope.openTeamScheduler = function () {
            var teamSchedulerAppUrl = configurationService.appConfiguration.planboardSettings.teamSchedulerAppUrl;
            // Use window.open() to open a new window/tab
            window.open(teamSchedulerAppUrl, '_blank');
        }
        // show the resource combinations view
        $scope.openResourceCombinationsView = function () {
            clearCurrentActivityTypeSelection();
            $state.transitionTo("planboard.resourceCombinations");
        }

        // get the height of the button bar contents
        $scope.getButtonBarContentsHeight = function () {
            return $scope.buttonBarHeight - ($scope.buttonBarPinned ? 4 : $scope.buttonBarMinHeight);
        }

        // hide the button bar when a dropdown is closed, keep button bar shown when a dropdown is opened
        $scope.setIsDropdown = function (visible, nr) {
            controlIsDroppedDown[nr] = visible;
            hideShowButtonBar(visible);
            if (nr == 4 || nr == 6) {
                // this is the open dropdown menu for Scenario(4), Counters(6)
                clearCurrentActivityTypeSelection();
            }
        }

        $scope.onActTypeDropDown = function (visible, nr) {

            $scope.setIsDropdown(visible, nr);

            var newFilter = new Dictionary();
            // Add the filter activity type
            if ($scope.actTypes.value(dropDownActivityTypesFilterId) == undefined) {
                $scope.actTypes.add(dropDownActivityTypesFilterId, { displayName: $scope.textLabels.FILTER_ACTIVITY_TYPES, id: dropDownActivityTypesFilterId, order: -Globals.maxInt, isActive: $scope.applyFilterPaletteForActivityTypes });
            }

            if (!categoriesIds.includes(dropDownActivityTypesFilterId)) {
                categoriesIds.push(dropDownActivityTypesFilterId);
            }
            if ($scope.applyFilterPaletteForActivityTypes && !categoriesIds.includes(dropDownActivityTypesSortId)) {
                categoriesIds.push(dropDownActivityTypesSortId);
            }

            // Add the sorter activity type when filtering is on 
            if ($scope.applyFilterPaletteForActivityTypes && $scope.actTypes.value(dropDownActivityTypesSortId) == undefined) {
                $scope.actTypes.add(dropDownActivityTypesSortId, { displayName: $scope.textLabels.SORT_ACTIVITY_TYPES, id: dropDownActivityTypesSortId, order: -Globals.maxInt + 1, isActive: $scope.applySortPaletteForActivityTypes });
            }

            // Remove sorting when filtering is off
            if (!$scope.applyFilterPaletteForActivityTypes && $scope.actTypes.value(dropDownActivityTypesSortId) != undefined) {
                $scope.actTypes.remove(dropDownActivityTypesSortId);
            }

            if (!$scope.applyFilterPaletteForActivityTypes && categoriesIds.includes(dropDownActivityTypesSortId)) {
                categoriesIds.remove(dropDownActivityTypesSortId);
            }

            // Add categories
            categoriesIds.forEach(categorieId => {
                newFilter.add(categorieId, $scope.actTypes.dict[categorieId]);
            });

            // Create dictionary for sorting
            var sortOrder = new Dictionary;
            $scope.userFilterAndSorting.data.forEach((item) => {
                sortOrder.add(item.activityTypeId, item.sortOrder);
            });

            // Get list of user selected activity types
            userSelectedActivityTypes = [];
            $scope.userFilterAndSorting.data.forEach((item) => {
                userSelectedActivityTypes.push(item.activityTypeId);
            });

            planboard.activityTypes.forEach((id, item) => {
                if (item.parentId === null) { // root activity type

                    // Filter activity types on the selected resource type
                    var resourceTypeIdList = [];

                    if (item.leafActivityTypeIdList != null) {
                        item.leafActivityTypeIdList.forEach(leafActivityId => {

                            // Find leaf activity
                            var leafActivity = planboard.activityTypes.getObject(leafActivityId);

                            // Add resource types to the array
                            if (resourceTypeIdList.length > 0) {
                                resourceTypeIdList = resourceTypeIdList.concat(leafActivity.resourceTypeIdList);
                            }
                            else {
                                resourceTypeIdList = leafActivity.resourceTypeIdList;
                            }

                        });
                    } else {
                        resourceTypeIdList = item.resourceTypeIdList;
                    }

                    if ($scope.applyFilterPaletteForActivityTypes || $scope.applySortPaletteForActivityTypes) {
                        // Filter activity types on user preference 
                        var includeActivityType = true;

                        if ($scope.applyFilterPaletteForActivityTypes && !userSelectedActivityTypes.includes(id)) {
                            includeActivityType = false;
                        }

                        if (resourceTypeIdList.includes($scope.resourceSelectionId) && includeActivityType) {
                            if ($scope.actTypes.dict[id] != null) {
                                // Set sort order only when filtering and sorting is applied on user preference
                                var activityType = $scope.actTypes.dict[id]
                                if ($scope.applySortPaletteForActivityTypes && $scope.applyFilterPaletteForActivityTypes) {
                                    activityType.order = sortOrder.getStore()[id];
                                }

                                newFilter.add(id, activityType);
                            }
                        }
                    } else {
                        // Display activity types alphabatically (Which is the standard) 
                        if (resourceTypeIdList.includes($scope.resourceSelectionId)) {
                            if ($scope.actTypes.dict[id] != null) {
                                var activityType = $scope.actTypes.dict[id]
                                newFilter.add(id, activityType);
                            }
                        }
                    }
                }
            });
            $scope.actTypesFiltered = newFilter;
        }

        // change the start date (left date) of the planboard
        $scope.startDateChanged = function (date) {
            if (date == null) {
                // go to today
                date = new Date();
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            }
            planboard.showDate(date, true);
            planboard.redrawAll();
            $timeout(function () { $scope.startDate = date; }, 1);
        }

        // scroll a number of days forward or back
        $scope.scrollDays = function (nrDays) {
            var d = planboard.getCurrentDate();
            d.setDate(d.getDate() + nrDays);
            planboard.showDate(d, true);
            planboard.redrawAll();
        }

        // activity type in button bar selected
        $scope.onActTypeChanged = function (itemId) {
            // Activity type Filter
            if (itemId === dropDownActivityTypesFilterId) {
                $window.location.href = "/angularomrp/planboard/filter-palette";
                return;
            }

            if (itemId === dropDownActivityTypesSortId) {
                $window.location.href = "/angularomrp/planboard/sort-palette";
                return;
            }

            planboard.selectedActivityTypeId = itemId;
            $scope.saveUserDisplaySettings();
            if (itemId != planboard.daymarkActivityTypeId) {
                planboard.daymarkStartTime = null;
                planboard.daymarkDurationMinutes = null;
                planboard.daymarkActivityTypeId = null;
            }

        }

        $scope.onAddModeClicked = function (addModeValue) {
            $scope.addMode = addModeValue;
            if (!addModeValue) {
                planboard.selectedActivityTypeId = -1;
            } else {
                planboard.selectedActivityTypeId = $scope.selectedActTypeId;
            }
        }

        // apply a change to the selected counters
        $scope.applyCountersChanged = function (itemId) {
            if (itemId >= 1000) {
                planboard.hideCounters();
                return;
            }
            var currentDate = planboard.getCurrentDate();
            var countersEntity = PlanboardCounters;
            if (itemId === 4) $scope.openCustomCounterPeriodView();
            else {
                countersEntity.countersWeekNr = countersEntity.countersMonthNr = -1;
                countersEntity.countersYearNr = currentDate.getFullYear();
            }
            if (itemId >= 1 && itemId <= 3) countersEntity.countersStartDate = countersEntity.countersEndDate = null;
            if (itemId === 1) {
                countersEntity.countersWeekNr = Timezone.getWeekNumber(currentDate).week;
                // Set correct year if Week Number is 1 and current month is december
                if (countersEntity.countersWeekNr === 1 && currentDate.getMonth() >= 11)
                    countersEntity.countersYearNr = currentDate.getFullYear() + 1;
            }
            if (itemId === 2) countersEntity.countersMonthNr = currentDate.getMonth() + 1;
            // set column sizes for counters
            for (var i = 0; i < planboard.areaCounters.cols.count; i++) {
                var colWidth = userService.getDisplaySettingNumber("planboard.countersColWidth" + i.toString(), -1);
                if (colWidth > 0) {
                    planboard.areaCounters.cols.setSize(i, colWidth, 16);
                    planboard.areaCounterHeader.cols.setSize(i, colWidth, 16);
                }
            }
            planboard.showCounters();
            planboard.timedRefresh();
        }

        // resource counters action in button bar
        $scope.onCountersChanged = function (itemId) {
            clearCurrentActivityTypeSelection();
            $scope.saveUserDisplaySettings();
            $scope.applyCountersChanged(itemId);
        }

        // zoom menu action in button bar
        $scope.onZoomChanged = function (itemId) {
            planboard.setZoom(planboard.zoomLevel, true, itemId);
            $scope.saveUserDisplaySettings();
        }

        // Toggle filter action in button bar 
        $scope.toggleFilterChanged = function (itemId) {
            switch (itemId) { 
                case 1:
                    $window.location.href = "/angularomrp/planboard/filtering";
                    break;
                case 2:
                    planboard.applyUnitFilterToResources = !planboard.applyUnitFilterToResources;
                    userService.setDisplaySettingNumber("planboard.applyUnitFilterToResources", planboard.applyUnitFilterToResources ? 1 : 0);
                    break;
                case 3:
                    planboard.applySkillsFilter = !planboard.applySkillsFilter;
                    userService.setDisplaySettingNumber("planboard.applySkillsFilter", planboard.applySkillsFilter ? 1 : 0);
                    break;
                case 4:
                    planboard.applyResourcesToHide = !planboard.applyResourcesToHide;
                    userService.setDisplaySettingNumber("planboard.applyResourcesToHide", planboard.applyResourcesToHide ? 1 : 0);
                    break;
                case 5:
                    $scope.applyUserPrefActivityTypeFilterToPlanboard = !$scope.applyUserPrefActivityTypeFilterToPlanboard;
                    userService.setDisplaySettingNumber("planboard.applyActivityFilterInPlanboard", $scope.applyUserPrefActivityTypeFilterToPlanboard ? 1 : 0);
                    break;  
                case 6:
                    $scope.applyUserPrefActivityTypeFilterToOpen = !$scope.applyUserPrefActivityTypeFilterToOpen;
                    userService.setDisplaySettingNumber("planboard.applyActivityFilterToOpen", $scope.applyUserPrefActivityTypeFilterToOpen ? 1 : 0);
                    break;
                default:
                    break;
            }
            
            initializeMenuBarItems();
            $scope.refreshClick(true);
        }   

        $scope.toggleSortingChanged = function (itemId) {
            switch (itemId) {
                case 1:
                    $window.location.href = "/angularomrp/planboard/sorting";
                    break;
                case 2:
                    planboard.applyCustomResourceFilter = !planboard.applyCustomResourceFilter
                    userService.setDisplaySettingNumber("planboard.applyResourceFilterInPlanboard", planboard.applyCustomResourceFilter ? 1 : 0);
                default:
                    break;
            }

            initializeMenuBarItems();
            $scope.refreshClick(true);
        }  

        $scope.getColorToggleFilter = function () {
            if (planboard.applyUnitFilterToResources || $scope.applyUserPrefActivityTypeFilterToPlanboard || $scope.applyUserPrefActivityTypeFilterToOpen || planboard.applyResourcesToHide || planboard.applySkillsFilter) {
                return true; //Orange color
            } else {
                return false; //Standard blue color
            }
        }

        $scope.getColorToggleSorting = function () {
            return planboard.applyCustomResourceFilter;
        }

        // save the current date from planboard with timeout 
        var saveCurrentDateFromPlanboardUsingTimeout = function (date) {
            if (saveCurrentDate === false) {
                saveCurrentDate = true;

                return;
            } 
            if (date.getTime() === 0) {
                return;
            }
            
            if (saveCurrentDateFromPlanboard) { $timeout.cancel(saveCurrentDateFromPlanboard); saveCurrentDateFromPlanboard = null; }
            if (date) saveCurrentDateFromPlanboard = $timeout(function () {
                userService.setDisplaySetting("planboard.currentLeftDate", date);
            }, 2000);
        }

        // test if counters have changed
        $scope.updateCounters = function () {
            var currentDate = planboard.getCurrentDate();
            saveCurrentDateFromPlanboardUsingTimeout(currentDate)
            if (!planboard.areaCounters.visible) return; // no counters are visible
            var countersEntity = PlanboardCounters;
            if (countersEntity.countersStartDate != null && countersEntity.countersEndDate != null) return; // fixed counters period is selected, so there will be no change
            if ((countersEntity.countersWeekNr >= 0 && countersEntity.countersWeekNr !== Timezone.getWeekNumber(currentDate).week) ||
                (countersEntity.countersMonthNr >= 0 && countersEntity.countersMonthNr !== currentDate.getMonth() + 1) ||
                (countersEntity.countersYearNr >= 0 && countersEntity.countersYearNr !== currentDate.getFullYear()))
                $scope.applyCountersChanged(countersEntity.countersWeekNr >= 0 ? 1 : countersEntity.countersMonthNr >= 0 ? 2 : 3);
        }

        // line height action in button bar
        $scope.onLineHeightChanged = function (itemId) {
            $timeout(function () {
                $scope.lineHeightValue = 0;
                planboard.autoRowHeight = false;

                var topRow = planboard.areaMain.rows.nrAtPos(planboard.areaMain.innerTop);
                var rowNr = planboard.activities.getResourceRowIndex(PlanboardResources.selectedResourceId);
                var currentLineHeight = planboard.getRowLineHeight(rowNr);

                switch (itemId) {
                    case 1:
                        planboard.setRowLineHeight(rowNr, 1);
                        break;
                    case 2:
                        planboard.setRowLineHeight(rowNr, Math.max(currentLineHeight - 1, 1));
                        break;
                    case 3:
                        planboard.setRowLineHeight(rowNr, currentLineHeight + 1);
                        break;
                    case 4:
                        // if itemId 4 is selected then the planboard should automatically enlarge rows when the row is drawn
                        $scope.lineHeightValue = 4;
                        planboard.autoRowHeight = true;
                        break;
                }

                planboard.areaMain.innerTop = planboard.areaResources.innerTop = planboard.areaMain.rows.getPos(topRow);
                planboard.redrawAll();

                if (featurePlanboardAPlanningBoard) {
                    $scope.saveUserDisplaySettings();
                }
            }, 0);
        };

        // scenarios selection in button bar
        $scope.onScenarioSelectionChanged = function (itemId) {
            clearCurrentActivityTypeSelection();

            if (itemId === 0) {
                userService.setUserVariable("selectedScenarioRequestId", planboard.scenarioId);
                $scope.transitionWithPermission("ScenarioManagement", "scenarioManagement", {});
                return;
            }
            $scope.showSpinner = true;

            userService.setDisplaySettingNumber("planboard.scenarioId", itemId);
            $scope.scenarioId = $scope.dropDownScenarioId = planboard.scenarioId = itemId;
            // Set the orange warning color to the planboard splitters if in a scenario
            setScenarioColor();

            var resourceIdList = planboard.activities.getResourceIdsInSelection($scope.resourceSelectionId);
            $scope.cachedResourceViolations.clear();
            $scope.refreshViolations(resourceIdList);
            planboard.activities.clearActivities();
            planboard.redrawAll();
            $scope.showSpinner = false;
        }

        // resource selection in button bar selected
        $scope.onResourceSelectionChanged = function (itemId) {
            (function (selectionId) {
                // clear the selected activity type
                if (selectionId != $scope.resourceSelectionId) {
                    clearCurrentActivityTypeSelection();
                }

                userService.setDisplaySettingNumber("planboard.resourceSelectionId", selectionId);
                // apply resourceType filter to open activities
                applyActivityTypeFilter(selectionId);
                // show this selection
                planboard.activities.activateResourceSelection(selectionId, true);
                // refresh (or initial load) of resources in this selection
                $scope.refreshResourceList(selectionId);
            })(itemId); // remember itemId as selectionId
        }

        // toggle pinning the button bar
        $scope.toggleButtonBarPinned = function () {
            $scope.buttonBarPinned = !$scope.buttonBarPinned;
            doResizePlanboard();
            $scope.saveUserDisplaySettings();
        }

        // move the contents of the button bar to the left
        $scope.moveButtonBarLeftEnabled = function () { return $scope.buttonBarOffsetX > getButtonBarMinOffsetX(); }
        $scope.moveButtonBarLeft = function () { scrollButtonBarContents($scope.buttonBarOffsetX - window.innerWidth * 0.2); }

        // move the contents of the button bar to the right
        $scope.moveButtonBarRightEnabled = function () { return $scope.buttonBarOffsetX < 0; }
        $scope.moveButtonBarRight = function () { scrollButtonBarContents($scope.buttonBarOffsetX + window.innerWidth * 0.2); }

        //show the preference button if the user is a full user
        $scope.isFullUser = function () { return userService.isFullUser; }
        $scope.featurePlanboardAPlanningBoard = function () { return featurePlanboardAPlanningBoard; }
        $scope.featureUseActivityRequestHandling = function () { return featureUseActivityRequestHandling; }
 
        // refresh all data
        $scope.refreshClick = function (resumeClick) {


            $scope.showSpinner = true;

            if (!resumeClick) {
                clearCurrentActivityTypeSelection();
            }
            $scope.saveUserDisplaySettings();
            $scope.cachedResourceViolations.clear();
            planboard.clearData();
            planboard.cancelInitialResources = false;
            planboard.activityTypesLoading = true;
            planboard.resourcesLoading = true;
            planboard.redrawAll(); // draws an empty planboard for user indication that something is happening
            Initialization.initializeLast(); // re-load activity types and resources
            $scope.loadPlanningStatus();
        }

        // zoom in
        $scope.zoomIn = function () {
            planboard.setZoom(planboard.zoomLevel + 1);
            $scope.saveUserDisplaySettings();
        }
        // zoom out
        $scope.zoomOut = function () {
            planboard.setZoom(planboard.zoomLevel - 1);
            $scope.saveUserDisplaySettings();
        }

        // when right-click on planboard
        $scope.onContextMenu = function (event) {
            event.preventDefault();
            return false;
        }

        // when clicked on the dimmed div, go back from the child view to the planboard
        $scope.closeChildView = function ($event) {
            if ($event && $event.target && $event.target.id === "dimmedDiv") {
                if (userService.getUserVariable("childViewPending") !== true)
                    $timeout(function () { window.history.back(); }, 100);
                $event.stopPropagation();
            }
        }

        // hide the wait spinner if mouse moved/clicked over the spinner
        $(document).ready(function () {
            if ("pointer-events" in document.documentElement.style) return; // no need, pointer-events exists.
            $("#waitSpinner").on("click dblclick mousedown mouseup mousemove mousewheel", function (e) {
                $scope.showSpinner = false;
                $(this).css("display", "none");
                return false;
            });
        });
    }
];