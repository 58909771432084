import { Planboard } from './../planboard/entities/planboard';
import * as Constants from './../utils/constants';
import * as Timezone from './../utils/timezone';

export interface INotificationService {
    subscribeToWebSocketEvent(controllerScope: any, eventName: any, eventAction: any);
    removeStringFromClientInfo(infoToRemove: any);
    addStringToClientInfo(newInfo: any);
}

export var notificationService = [
    "$http", "$location", "$browser", "$timeout", "$rootScope", "$window", "userService",
    function ($http, $location, $browser, $timeout, $rootScope, $window, userService) {
        var svc = this;
        var webSocket;
        var retryDelayInit = 500;
        var retryTimerRunning = false;
        var periodicTimerRunning = false;
        var clientInfo = [];
        var lastReceivedTime = 0;
        var lastReceivedDate = null;
        var listenerCleanups = [];
        var reconnecting = false;

        // Adds a string to the client info that is sent to the server (e.g. to tell what we are currently viewing) and sends a ping message.
        svc.addStringToClientInfo = function (newInfo) {
            clientInfo.push(newInfo);
            if (webSocket != null) {
                sendPingMessage();
            }
        }

        // Removes a string from the client info and sends a ping message.
        svc.removeStringFromClientInfo = function (infoToRemove) {
            var index = clientInfo.indexOf(infoToRemove);
            if (index > -1) {
                clientInfo.splice(index, 1);
                sendPingMessage();
            }
        }

        // Subscribes a controller to an event. This executes the specified action function when the event is raised.
        svc.subscribeToWebSocketEvent = function (controllerScope, eventName, eventAction) {

            var listenerCleanup = $rootScope.$on(eventName, eventAction);
            listenerCleanups.push(listenerCleanup);

            // Execute listener cleanup code upon destruction of the controller.
            controllerScope.$on("$destroy",
                function () {
                    var index = listenerCleanups.indexOf(listenerCleanup);
                    if (index > -1) listenerCleanups.splice(index, 1);

                    listenerCleanup();
                });
        }

        // Starts periodic attempts to reconnect to the server.
        function startConnectionRetries() {
            if (!retryTimerRunning && userService.isAuthenticated && userService.isFullUser) {
                retryTimerRunning = true;
                reconnecting = true;
                $timeout(function () {
                    retryTimerRunning = false;
                    if (userService.isAuthenticated && userService.isFullUser) {
                        //console.log("Attempting to reconnect to server.", retryDelayInit);
                        retryDelayInit *= 2;
                        initWebSocket();
                    }
                }, retryDelayInit);
            }
        }

        function initWebSocket() {
            // initialize lastReceivedTime
            lastReceivedTime = (new Date()).getTime();

            // determine the websocket address
            let baseUrl = $location.host() + ":" + $location.port();
            if (window['baseUrl']) {
                baseUrl = window['baseUrl'].replace('https://', '');
            }

            const handlerUrl = "wss://" + baseUrl + $browser.baseHref() + "/api/Notification";

            // close current websocket and initialize a new one
            closeWebSocket();

            // stop if this is not a full user, resource users do not need the web socket connection
            if (!userService.isFullUser) return;

            var currentUser = $window.localStorage.getItem(Constants.currentUserStorageKey) === undefined ? undefined : JSON.parse($window.localStorage.getItem(Constants.currentUserStorageKey))
            if (currentUser == null) return;

            webSocket = new WebSocket(handlerUrl, ["access_token", currentUser.token]);

            // open connection handler.
            webSocket.onopen = function () {
                retryDelayInit = 500; // reset delay to default if successfully opened
                $rootScope.$broadcast("notif:websocketOpened", {});

                // if we are reconnecting, request the changed activities since we last received data
                // requesting any route on Activities will also attach the activity events server side
                if (reconnecting && lastReceivedDate) {
                    var postObject = {
                        fromDateInclusive: Timezone.rollDateForWebApi(lastReceivedDate),
                        scenarioId: Planboard.scenarioId
                    }
                    $http.post("api/Activities/GroupChanges", postObject).then(function (response) {
                        if (Planboard.dataRequested) { // only if the planboard has already been opened this session
                            Planboard.readActivitiesWithRootIds(response.data);
                        }
                    },
                        function (response) { });
                }
                reconnecting = false;
            };

            // message data handler.
            webSocket.onmessage = function (e) {
                try {
                    if (e.data == null || e.data.length === 0 || e.data.charAt(0) !== "{") return; // not json
                    lastReceivedDate = new Date(); // remember lastReceivedDate, because lastReceivedTime gets updated in initWebSocket
                    lastReceivedTime = lastReceivedDate.getTime(); // update last received time
                    var message = JSON.parse(e.data);
                    console.log("[WebSocket received]", message, lastReceivedTime);
                    if (message.messageType === "logoff") {
                        closeWebSocket();
                        userService.logoff();
                        userService.refreshPage();
                    } else {
                        // raise an event with the name that corresponds with the message type
                        $rootScope.$emit(message.messageType, message);
                    }
                } catch (errMessage) {
                    //console.log("[WebSocket error in OnMessage]", errMessage);
                }
            };

            // close event handler.
            webSocket.onclose = function () {
                //console.log("[WebSocket closed]");
                $rootScope.$broadcast("notif:websocketClosed", {});
            };

            // error event handler.
            webSocket.onerror = function (e) {
                console.log("[WebSocket error]", e);
                // try to reconnect the websocket with an automatic increasing delay
            }

            // periodic ping message (keep alive system)
            var periodicPingInterval = 20000;
            var periodicPing = function () {
                if (webSocket && webSocket.OPEN && webSocket.readyState === 1) {
                    // websocket is open so we send the ping message
                    sendPingMessage();
                } else if (userService.isAuthenticated) {
                    // try to reconnect the websocket with an automatic increasing delay
                    if ((new Date()).getTime() > lastReceivedTime + periodicPingInterval)
                        startConnectionRetries();
                } else {
                    // no websocket open and not authenticated, might as well stop this timer
                    periodicTimerRunning = false;
                }
                if (periodicTimerRunning) {
                    // restart the timer
                    $timeout(periodicPing, periodicPingInterval);
                }
            }
            if (!periodicTimerRunning) {
                periodicTimerRunning = true;
                $timeout(periodicPing, 500); // start the timer (the first time rather quick, the next times with periodicPingInterval)
            }
        }

        // Function to send a ping message, either periodic or triggered by changing client info.
        function sendPingMessage() {
            if (!webSocket) return;
            var pingMessage = { "messageType": "ping", "data": clientInfo };

            // send data if WebSocket is opened.
            if (webSocket.OPEN && webSocket.readyState === 1) {
                webSocket.send(JSON.stringify(pingMessage));
            }
        }

        function closeWebSocket() {
            if (webSocket) {
                try {
                    webSocket.close();
                    webSocket = null;
                } catch (err) {
                    console.log(err);
                }
            }
        }

        function disconnect() {

            while (listenerCleanups.length > 0) {
                var listenerCleanup = listenerCleanups.splice(0, 1)[0];
                listenerCleanup();
            }

            closeWebSocket();
        }

        function sendData(message) {
            // send data if WebSocket is opened.
            if (webSocket.OPEN && webSocket.readyState === 1) {
                webSocket.send(message);
            }
        }

        svc.connect = initWebSocket;
        svc.disconnect = disconnect;
        svc.send = sendData;
    }
];