import { Dictionary } from './../../utils/dictionary';
import * as Constants from './../../utils/constants';

import { AreaControl } from './../controls/areaControl';
import { BasicControl } from './../controls/basicControl';
import { ScrollBarControl } from './../controls/scrollBarControl';

import { ActivityInfo } from './../drawing/activityInfo';
import * as CachedObjectIds from './../drawing/cachedObjectIds';
import * as Symbols from './../drawing/symbols';
import * as Drawing from './../drawing/timeline';

import * as Globals from './../utils/globals';
import { Rect } from './../utils/rect';
import { TimeSpan } from './../utils/timespan';

import { Activity, ActivityList } from './../entities/activity';
import { ActivityType, TimeSlotItem } from './../entities/activitytype';
import { Planboard } from './../entities/planboard';
import { StartEndTime } from './../entities/startEndTime';

import { OpenActivities } from './openActivities'
import { PlanboardResources } from './planboardResources';

export class PlannedActivities {
    /**
        * unique Id of a new activity, will be decreased every time a new activity is planned
        */
    static newActivityId = -1000;

    /**
        * round minutes value, when dragging in new activity or changing an activities times
        */
    static roundMinutes = 15;

    /**
        * Indicates if the combined activity type filter should be applied to planned activities (see openActivities.ts).
        */
    static activityTypeFilterActive = false;

    /**
        * Dictionary of activity type ids based upon the selected root activity types in user preferences.
        */
    static activityTypeFilterIds = new Dictionary();


    /**
        * Start date/time of the activity that was selected before dragging.
        */
    static mouseDownActivityStart = null;

    /**
        * End date/time of the activity that was selected before dragging.
        */
    static mouseDownActivityEnd = null;

    /**
        * The last activity list used when dragging in a new activity.
        */
    private static lastActivityList: ActivityList = null;

    /**
        * The line number in a row where the mouse was last when a mouse button was pressed.
        */
    private static mouseDownLineNr: number;

    /**
        * Contains the row numbers that should automatically be enlarged.
        */
    private static autoEnlargeRowNrs = new Dictionary();

    /**
        * round a date to within a number of minutes
        */
    private static roundDateMinutes(d: Date, roundMinutes: number): Date {
        if (roundMinutes <= 0) return d;
        let min = d.getHours() * 60 + d.getMinutes();
        min = (Math.floor(min / roundMinutes) * roundMinutes) + (min % roundMinutes > (roundMinutes / 2) ? roundMinutes : 0);
        return TimeSpan.fromDateNoTime(d).addMinutes(min).toDate();
    }

    static initialize() {
        this.createAreaControl();
        this.initDrawCell();
        this.initMouseDownCell();
        this.initMouseMoveCell();
        this.initDragDropCell();
        this.initMouseLeave();
        this.initMouseUpCell();
        this.initScrollEnd();
        this.initMouseDblClick();
    }

    private static createAreaControl() {
        const areaMain = Planboard.areaMain = new AreaControl(Planboard.split1.getSplitArea(1, 1), 0, 0, 100, 100, 3000, 2000);
        areaMain.name = "areaMain";
        areaMain.setUseBackbuffer(true);
        areaMain.setAlign(0, 0, 0, 0);
        areaMain.backcolor = Globals.windowColor;
        areaMain.gridColor = Globals.gridLinesColor;
        areaMain.clearColor = areaMain.backcolor;
        areaMain.cols.setSize(39, Drawing.calculateTimeLineWidth(Planboard.dayStartHour, Planboard.dayEndHour, Planboard.zoomLevel), 0);
        areaMain.rows.setSize(Planboard.activities.getResourceCount() - 1, Globals.fontHeight + 1 + Planboard.getPlanningStatusRowHeight(), 0);
        areaMain.hBar.barStep = 20;
        areaMain.enableDragging = false;
    }

    private static initMouseDblClick() {
        Planboard.areaMain.mouseDblClick = (x: number, y: number, button: number) => { };
    }

    private static initScrollEnd() {
        Planboard.areaMain.scrollEnd = (t: AreaControl, sb: ScrollBarControl, action: number) => {
            if (t.hBar === sb && t.innerLeft <= 1) {
                // scroll back one week
                sb.stopWheelScroll();
                Planboard.leftDate = TimeSpan.fromDate(Planboard.leftDate).addDays(-7).toDate();
                Planboard.areaMain.innerLeft = Planboard.areaDate.innerLeft = Planboard.areaToPlan.innerLeft = Planboard.areaMain.cols.getPos(7);
                Planboard.redrawAll();
                sb.recalculateMouseDownPos();
                if (action !== 1 && action !== 2) {
                    sb.disableScrollTimer();
                    sb.mouseLeave();
                }
                Planboard.triggerEvents(Planboard.onLeftDateChanged);
            }
            else if (t.hBar === sb && t.innerLeft >= sb.maxValue - 1) {
                // scroll forward one week
                sb.stopWheelScroll();
                let leftCol = t.cols.nrAtPos(t.innerLeft);
                const colOffset = t.innerLeft - t.cols.getPos(leftCol);
                leftCol = Math.max(leftCol - 7, 0);
                Planboard.leftDate = TimeSpan.fromDate(Planboard.leftDate).addDays(7).toDate();
                Planboard.areaMain.innerLeft = Planboard.areaDate.innerLeft = Planboard.areaToPlan.innerLeft = Planboard.areaMain.cols.getPos(leftCol) + colOffset;
                Planboard.redrawAll();
                sb.recalculateMouseDownPos();
                if (action !== 1 && action !== 2) {
                    sb.disableScrollTimer();
                    sb.mouseLeave();
                }
                Planboard.triggerEvents(Planboard.onLeftDateChanged);
            }
        };
    }

    private static initDrawCell() {
        Planboard.areaMain.drawCell = (t: AreaControl, ctx: CanvasRenderingContext2D, col: number, row: number, colWidth: number, rowHeight: number, contextX: number, contextY: number) => {
            const resourceId = Planboard.activities.getResourceId(row);
            const dayOfWeek = (col + Planboard.firstDayOfWeek) % 7; // 0 = sunday, 1 = monday, etc...
            if (dayOfWeek === 0 || dayOfWeek === 6 || resourceId === PlanboardResources.selectedResourceId) {
                ctx.fillStyle = dayOfWeek === 0 || dayOfWeek === 6
                    ? (resourceId === PlanboardResources.selectedResourceId ? Globals.darkerHighlightColor : Globals.light3DColor)
                    : Globals.highlightColor;
                ctx.fillRect(contextX, contextY, colWidth - 1, rowHeight - 1);
            }

            let activities = Planboard.activities.getActivityList(resourceId, Planboard.leftDate, col, true);
            let enlargeRow = false;
            let maxVisibleLineNr = 0;

            if (activities != null && activities.items.length > 0) {
                const dayNr = TimeSpan.getDayNr(Planboard.leftDate) + col;
                let cornerSize = (Globals.fontHeight >> 1) - 1; // memo corner size
                let activityHeight = Globals.fontHeight - 2; // height of an activity
                let actY = contextY;
                let actNr = 0;
                while (actNr < activities.items.length) {
                    let activityVisible = true;
                    if (activityVisible) {
                        let lineNr = activities.items[actNr].lineNr;
                        let startX = Drawing.dateTimeToX(activities.items[actNr].startDate, dayNr, colWidth, false);
                        let endX = Drawing.dateTimeToX(activities.items[actNr].endDate, dayNr, colWidth, true);
                        let activityType = Planboard.activityTypes.getObject(activities.items[actNr].activityTypeId) as ActivityType;
                        let mainAct = Planboard.activities.getMainActivity(activities.items[actNr].id);
                        activityVisible = this.activityTypeVisible(activityType, this.activityTypeFilterActive);
                        if (lineNr === -1) {
                            let compareActivities = activities;
                            if (!TimeSpan.sameDayNr(activities.items[actNr].startDate, activities.items[actNr].endDate)) {
                                compareActivities = Planboard.activities.getLoadedActivitiesBetween(resourceId,
                                    activities.items[actNr].startDate, activities.items[actNr].endDate);
                                if (compareActivities == undefined) compareActivities = activities;
                            }
                            lineNr = 0;
                            let actNr2 = 0;
                            while (actNr2 < compareActivities.items.length) {
                                if (compareActivities.items[actNr2].lineNr === lineNr &&
                                    compareActivities.items[actNr2].endDate > activities.items[actNr].startDate &&
                                    compareActivities.items[actNr2].startDate < activities.items[actNr].endDate) {
                                    lineNr++;
                                    actNr2 = 0;
                                } else
                                    actNr2++;
                            }
                            activities.items[actNr].lineNr = lineNr;
                        }
                        actY = contextY + lineNr * Globals.fontHeight;
                        if (activityVisible) {
                            maxVisibleLineNr = Math.max(maxVisibleLineNr, lineNr);
                        }
                        if (activityVisible && Planboard.controller.dragDrop) {
                            if (Planboard.selectedActivity != null && Planboard.selectedActivity.id === activities.items[actNr].id)
                                activityVisible = false; // do not show the selected activity if it is being dragDropped
                        }
                        if (activityVisible && actY + activityHeight < contextY + rowHeight) { // draw activity
                            if (lineNr < 0) {
                                activityType.draw(ctx, contextX + startX, contextY, endX - startX, rowHeight - 1, true);
                            }
                            else {
                                // check if completely filled (do we want client to do this?)
                                if (activities.items[actNr].filled === 2) {
                                    activities.items[actNr].filled = 0;
                                    Planboard.activities.checkFilled(activities.items[actNr].id);
                                }
                                activityType.draw(ctx, contextX + startX, actY + 1, endX - startX, activityHeight, activities.items[actNr].filled === 1);
                                // memo corner
                                if (mainAct && mainAct.memoId != null || activities.items[actNr].memoId) {
                                    Symbols.drawCorner(CachedObjectIds.memoCornerId, ctx, contextX + Math.min(endX - 1 - cornerSize, colWidth - 1 - cornerSize), actY + activityHeight - cornerSize, cornerSize, true, true, "rgb(255,15,15)", "rgb(239,0,0)");
                                }
                                // selected?
                                if ((Planboard.selectedActivity != null && Planboard.selectedActivity.id === activities.items[actNr].id) ||
                                    (Planboard.multiSelectedActivities != null && Planboard.multiSelectedActivities.containsKey(activities.items[actNr].id))) {
                                    Symbols.drawBorder(contextX + startX, actY, endX - startX, Globals.fontHeight, 2, Globals.windowTextColor, ctx);
                                }
                            }
                        }
                        if (activityVisible && actY + activityHeight > contextY + rowHeight) {
                            enlargeRow = true; // show enlarge row corner
                        }
                    }
                    if (!activityVisible) {
                        // permanently hide this activity (unless it is the selected activity)
                        if (Planboard.selectedActivity !== activities.items[actNr])
                            activities.items[actNr].lineNr = -3;
                    }
                    actNr++;
                }
            }
            else if (activities == undefined) {
                if (Planboard.activities.getLastReceived(resourceId, Planboard.leftDate, col) === 0) {
                    // data was not yet received for this resource for this day
                    // currently a spinner is visible if data is pending
                }
            }

            const planningStatusHeight = Planboard.getPlanningStatusRowHeight();
            if (planningStatusHeight > 0) {
                // draw resource planning status line on bottom of the cell
                const resource = Planboard.activities.getResource(resourceId);
                const planningStatus = resource.planningStatus || Planboard.activities.getResourcePlanningStatus(resource);
                let colDate: Date = null;
                if (planningStatus != null && planningStatus.length > 0) {
                    // planningStatus array is sorted by date, so we find the first entry where the cell date is before this planningStatus date
                    colDate = new Date(Planboard.leftDate.getTime());
                    colDate.setDate(colDate.getDate() + col - 1); // remove one day from the colDate because the planningStatus date is inclusive
                    for (let i = 0; i < planningStatus.length; i++)
                        if (colDate < planningStatus[i].untilDate) {
                            const planningStatusColor = Globals.planningStatusColors[planningStatus[i].status];
                            if (planningStatusColor != null) {
                                ctx.fillStyle = planningStatusColor;
                                ctx.fillRect(contextX, contextY + rowHeight - planningStatusHeight - 1, colWidth, planningStatusHeight);
                                break;
                            }
                        }
                }

                // when planning status is shown, the organization unit membership will also be shown.
                // if the resource does not have a period bound property for an organization unit for this day, then a grey line will be drawn
                const units = resource.units || Planboard.activities.getResourceUnits(resource);
                if (units != null && units.count > 0) { // only if the resource has at least 1 unit, else the resource should not even be in the list
                    colDate = new Date(Planboard.leftDate.getTime());
                    colDate.setDate(colDate.getDate() + col);
                    let unitValue = resource.lastMembershipUnit; // the last unit where the resource had an availability
                    let resourceIsAvailable = (unitValue != null &&
                        (unitValue.start == null || unitValue.start <= colDate) &&
                        (unitValue.end == null || unitValue.end > colDate));
                    if (!resourceIsAvailable)
                        units.forEach((key, value) => {
                            let unitList: any[] = value;
                            for (let i = unitList.length - 1; i >= 0; i--) {
                                let unit = unitList[i];
                                if ((unit.start == null || unit.start <= colDate) &&
                                    (unit.end == null || unit.end > colDate)) {
                                    resourceIsAvailable = unit.currentUserHasReadPermissionToOrgUnit;
                                    if (unit.currentUserHasReadPermissionToOrgUnit) {
                                        resourceIsAvailable = true;
                                        resource.lastMembershipUnit = unit; // remember this as the last unit where the resource has an availability
                                    } else {
                                        resourceIsAvailable = false;
                                    }
                                    break;
                                }
                            }
                        });
                    if (!resourceIsAvailable) {
                        ctx.fillStyle = Globals.resourceUnavailable;
                        ctx.fillRect(contextX, contextY + rowHeight - planningStatusHeight - 1, colWidth, planningStatusHeight);
                    }
                }
            }

            if (enlargeRow) { // draw enlarge row corner
                let cornerSize = (Globals.fontHeight >> 1) + 1;
                Symbols.drawCorner(CachedObjectIds.enlargeRowId, ctx, contextX + colWidth - cornerSize, contextY + rowHeight - cornerSize, cornerSize,
                    true, true, Globals.face3DColor, Globals.gridLinesColor, "+", Globals.darker3DColor);

                if (Planboard.autoRowHeight) {
                    let oldMaxLineNr = this.autoEnlargeRowNrs.value(row);
                    if (oldMaxLineNr == undefined || maxVisibleLineNr > oldMaxLineNr) {
                        this.autoEnlargeRowNrs.add(row, maxVisibleLineNr);
                        Planboard.timedRefresh();
                    }
                }
            }
        }
    }

    /**
        * Get if an activity type is visible according to combinedActivityTypeOrder dictionary.
        * @param activityType the activity type to test
        * @param applyFilter if the combinedActivityTypeOrder should be applied
        */
    static activityTypeVisible(activityType: ActivityType, applyFilter: boolean) {
        return activityType != null && (!applyFilter ||
            activityType.categoryId === ActivityType.absenceCategoryId ||
            (this.activityTypeFilterIds != null && this.activityTypeFilterIds.containsKey(activityType.id)));
    }

    static expandRow(t: AreaControl, col: number, row: number, activities: ActivityList = null, selectActivity: Activity = null): void {
        this.enlargeRow(t, col, row, activities, selectActivity)
    }

    static expandRows(t: AreaControl): void {
        if (this.autoEnlargeRowNrs.count === 0) return;

        this.autoEnlargeRowNrs.forEach((key, value) => {
            let maxLines = value + 1; // need to add one because lineNr starts at 0
            let newRowHeight = (Globals.fontHeight * maxLines) + 1 + Planboard.getPlanningStatusRowHeight();
            if (newRowHeight > t.rows.getSize(key)) {
                t.resizeRow(key, newRowHeight, true);
            }
        });

        this.autoEnlargeRowNrs.clear();
    }

    /**
        * enlarge a specific row based upon content in a cell, will return true if the row was actually enlarged
        */
    private static enlargeRow(t: AreaControl, col: number, row: number, activities: ActivityList = null, selectActivity: Activity = null): boolean {
        let maxLineNr = 1;
        if (!activities) activities = Planboard.activities.getActivityList(Planboard.activities.getResourceId(row), Planboard.leftDate, col, true);
        if (!activities) return false;
        if (!selectActivity) selectActivity = Planboard.selectedActivity;
        for (let actNr = 0; actNr < activities.items.length; actNr++)
            maxLineNr = Math.max(maxLineNr, activities.items[actNr].lineNr + 1);
        const newRowHeight = (Globals.fontHeight * maxLineNr) + 1 + Planboard.getPlanningStatusRowHeight();
        if (newRowHeight > t.rows.getSize(row)) {
            Planboard.selectedActivity = selectActivity;
            const resourceId = Planboard.activities.getResourceId(row);
            Planboard.saveResourceLineheight(resourceId, newRowHeight);
            t.resizeRow(row, newRowHeight, true);
            return true;
        }
        return false;
    }

    /**
        * find the nearest index in timeSlots
        */
    private static findNearestTimeSlotIndex(minutes: number, timeSlots: TimeSlotItem[]): number {
        let minDiffTime = Globals.maxInt;
        let foundIndex = 0;
        for (let i = 0; i < timeSlots.length; i++) {
            let timeSlotStartMinutes = timeSlots[i].startMinutes;
            let timeSlotEndMinutes = timeSlots[i].endMinutes;

            // timeslot interval spills over next day
            if (timeSlotStartMinutes > timeSlotEndMinutes) {
                timeSlotEndMinutes = 24 * 60 + timeSlotEndMinutes;
            }

            const diffTime = minutes < timeSlotStartMinutes ? timeSlotStartMinutes - minutes
                : minutes > timeSlotEndMinutes ? minutes - timeSlots[i].endMinutes : 0;
            if (diffTime < minDiffTime) {
                minDiffTime = diffTime;
                foundIndex = i;
            }
        }
        return foundIndex;
    }

    /**
        * perform an action for each activity in actList that overlaps with act, ignoring act if it exists in actList.
        * @param actList the list of activities to loop over
        * @param act the activity to test against each activity in actList
        * @param newStartEndTime optional, object containing new start and end time of the activity
        * @param matchLineNr the activities must have a matching line nr
        * @param action the action to take for each overlapping activity
        */
    private static forEachOverlappingActivity(actList: ActivityList, act: Activity,
        newStartEndTime: StartEndTime, matchLineNr: boolean,
        action: (overlappingActivity: Activity) => void) {
        if (actList == null || actList.items == null || actList.items.length <= 0) return;
        let actStart = newStartEndTime == null ? act.startDate.getTime() : newStartEndTime.startTime;
        let actEnd = newStartEndTime == null ? act.endDate.getTime() : newStartEndTime.endTime;
        for (let i = 0; i < actList.items.length; i++)
            if (actList.items[i].id !== act.id && (!matchLineNr || actList.items[i].lineNr === act.lineNr)) {
                const otherStart = actList.items[i].startDate.getTime();
                const otherEnd = actList.items[i].endDate.getTime();
                if (actStart < otherEnd && actEnd > otherStart) {
                    action(actList.items[i]);
                    // action might have changed the times of act, re-initiate the variables
                    actStart = newStartEndTime == null ? act.startDate.getTime() : newStartEndTime.startTime;
                    actEnd = newStartEndTime == null ? act.endDate.getTime() : newStartEndTime.endTime;
                }
            }
    }

    private static initMouseUpCell() {
        Planboard.areaMain.mouseUpCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            var startTime: TimeSpan;
            var endTime: TimeSpan;
            var timeSlotIndex: number;
            let redraw = false;
            let readOnly = Planboard.readOnly;

            // planning a new activity by clicking?
            if (button === 0 && row >= 0 && col >= 0 && !readOnly &&
                Planboard.selectedActivity == null && Planboard.resizeActivity == null && Planboard.mouseDownDate != null &&
                Planboard.selectedActivityTypeId > 0 && Planboard.lastMouseDownPos != null) {
                // plan a new activity (nothing selected or resizing and a valid activityTypeId is selected)
                const actType = Planboard.activityTypes.getObject(Planboard.selectedActivityTypeId) as ActivityType;
                // any activity type with default time slots or a daymark with stored daymarkActivityTypeId
                if (actType != null && actType.defaultTimeSlotList.length > 0 || (actType.categoryId === ActivityType.daymarkCategoryId && Planboard.daymarkActivityTypeId == actType.id)) {
                    // activity type with default time slots
                    if (actType.defaultTimeSlotList.length > 0) {
                        // snap to defaultTime
                        const clickMinutes = Planboard.mouseDownDate.getHours() * 60 +
                            Planboard.mouseDownDate.getMinutes();
                        timeSlotIndex = this.findNearestTimeSlotIndex(clickMinutes, actType.defaultTimeSlotList);
                        startTime = TimeSpan.fromDateNoTime(Planboard.mouseDownDate)
                            .addMinutes(actType.defaultTimeSlotList[timeSlotIndex].startMinutes);
                        endTime = TimeSpan.fromDateNoTime(Planboard.mouseDownDate)
                            .addMinutes(actType.defaultTimeSlotList[timeSlotIndex].endMinutes);
                        if (endTime.totalMiliseconds <= startTime.totalMiliseconds) endTime = endTime.addDays(1);
                    } else {
                        // daymark with no timeslots: get stored start/end times
                        startTime = TimeSpan.fromDate(new Date(Planboard.mouseDownDate.getFullYear(),
                            Planboard.mouseDownDate.getMonth(),
                            Planboard.mouseDownDate.getDate(),
                            Planboard.daymarkStartTime.getHours(),
                            Planboard.daymarkStartTime.getMinutes()));
                        endTime = TimeSpan.fromDate(startTime.toDate())
                            .addMinutes(Planboard.daymarkDurationMinutes);
                    }

                    // create new temporary activity
                    this.newActivityId--;
                    const newResourceId = t.name === Planboard.areaMain.name ? Planboard.activities.getResourceId(Planboard.selectedActivityRow) : null;
                    const newActivity = new Activity(this.newActivityId,
                        Planboard.adjustToUtc(startTime.toDate()), Planboard.adjustToUtc(endTime.toDate()),
                        Planboard.selectedActivityTypeId, newResourceId, null, Constants.StatusActivityPlanned);
                    newActivity.filled = 0;

                    Planboard.activities.addActivity(newActivity, false); // actually add the temporary activity to memory
                    Planboard.selectedActivity = newActivity; // select the temporary activity
                    Planboard.newActivityGroup(newActivity); // send to WebApi
                    redraw = true;
                }
            }

            // resizing an existing activity, or dragging in a new activity
            if (Planboard.resizeActivity != null && !readOnly) {
                if (Planboard.isAnyActivityFromGroupLocked(Planboard.resizeActivity.id)) {
                    readOnly = true;
                }
                else if (Planboard.resizeActivity.id < 0) {
                    // dragging in a new activity
                    const newActivity = Planboard.resizeActivity;
                    const actType = Planboard.activityTypes.getObject(newActivity.activityTypeId) as ActivityType;
                    let startMinutes = newActivity.startDate.getHours() * 60 + newActivity.startDate.getMinutes();
                    let endMinutes = newActivity.endDate.getHours() * 60 + newActivity.endDate.getMinutes();
                    startTime = TimeSpan.fromDateNoTime(newActivity.startDate).addMinutes(startMinutes);
                    endTime = TimeSpan.fromDateNoTime(newActivity.endDate).addMinutes(endMinutes);
                    const draggedStartEnd = new StartEndTime(startTime.totalMiliseconds, endTime.totalMiliseconds);
                    if (actType && actType.defaultTimeSlotList && actType.defaultTimeSlotList.length > 0) {
                        // snap to defaultTime
                        timeSlotIndex = this.findNearestTimeSlotIndex(startMinutes, actType.defaultTimeSlotList);
                        startMinutes = actType.defaultTimeSlotList[timeSlotIndex].startMinutes;
                        timeSlotIndex = this.findNearestTimeSlotIndex(endMinutes, actType.defaultTimeSlotList);
                        endMinutes = actType.defaultTimeSlotList[timeSlotIndex].endMinutes;
                    } else if (this.roundMinutes > 0) {
                        // round minutes
                        startMinutes = (Math.floor(startMinutes / this.roundMinutes) * this.roundMinutes) +
                            (startMinutes % this.roundMinutes > (this.roundMinutes / 2) ? this.roundMinutes : 0);
                        endMinutes = (Math.floor(endMinutes / this.roundMinutes) * this.roundMinutes) +
                            (endMinutes % this.roundMinutes > (this.roundMinutes / 2) ? this.roundMinutes : 0);
                    }
                    startTime = TimeSpan.fromDateNoTime(newActivity.startDate).addMinutes(startMinutes);
                    endTime = TimeSpan.fromDateNoTime(newActivity.endDate).addMinutes(endMinutes);
                    if (endTime.totalMiliseconds <= startTime.totalMiliseconds) endTime = endTime.addDays(1);
                    // test for overlap with other activities on the same row and the same lineNr
                    const activities = t.name === Planboard.areaMain.name
                        ? Planboard.activities.getActivityList(Planboard.activities.getResourceId(Planboard.selectedActivityRow), Planboard.leftDate, col, true)
                        : OpenActivities.getActivitiesInCell(col, Planboard.selectedActivityRow);
                    if (activities != null && activities.items.length > 0) {
                        // find all available timeslots between and around already planned activities
                        let timeSlots: StartEndTime[] = [];
                        timeSlots.push(new StartEndTime(startTime.totalMiliseconds, endTime.totalMiliseconds));
                        for (let i = 0; i < activities.items.length; i++) {
                            if (activities.items[i].id === newActivity.id) continue;
                            const otherStartTime = activities.items[i].startDate.getTime();
                            const otherEndTime = activities.items[i].endDate.getTime();
                            let j = timeSlots.length;
                            while (j-- > 0) {
                                if (otherStartTime <= timeSlots[j].startTime && otherEndTime >= timeSlots[j].endTime) {
                                    timeSlots.splice(j, 1);
                                } else if (otherStartTime > timeSlots[j].startTime && otherEndTime < timeSlots[j].endTime) {
                                    timeSlots.push(new StartEndTime(otherEndTime, timeSlots[j].endTime));
                                    timeSlots[j].endTime = otherStartTime;
                                } else if (otherStartTime <= timeSlots[j].startTime && otherEndTime > timeSlots[j].startTime) {
                                    timeSlots[j].startTime = otherEndTime;
                                } else if (otherEndTime >= timeSlots[j].endTime && otherStartTime < timeSlots[j].endTime) {
                                    timeSlots[j].endTime = otherStartTime;
                                }
                            }
                        }
                        // choose the timeslot with the least deviation from the dragged times
                        let changedStartEnd = new StartEndTime(startTime.totalMiliseconds, endTime.totalMiliseconds);
                        let leastError = Globals.maxInt;
                        for (let i = 0; i < timeSlots.length; i++) {
                            const deviationError = Math.abs(draggedStartEnd.startTime - timeSlots[i].startTime) + Math.abs(draggedStartEnd.endTime - timeSlots[i].endTime);
                            if (deviationError < leastError) {
                                leastError = deviationError;
                                changedStartEnd = timeSlots[i];
                            }
                        }
                        if (changedStartEnd.startTime < changedStartEnd.endTime) {
                            startTime.totalMiliseconds = changedStartEnd.startTime;
                            endTime.totalMiliseconds = changedStartEnd.endTime;
                        }
                    }
                    newActivity.startDate = startTime.toDate();
                    newActivity.endDate = endTime.toDate();
                    if (actType.categoryId === ActivityType.daymarkCategoryId) {
                        Planboard.daymarkStartTime = newActivity.startDate;
                        Planboard.daymarkDurationMinutes =
                            (TimeSpan.fromDate(newActivity.endDate).totalMiliseconds -
                                TimeSpan.fromDate(newActivity.startDate).totalMiliseconds) / 1000 / 60;
                        Planboard.daymarkActivityTypeId = actType.id;
                    }
                    Planboard.newActivityGroup(newActivity); // send to WebApi
                } else {
                    Planboard.moveActivityGroup(Planboard.resizeActivity.id, 0, Planboard.resizeActivity.startDate, Planboard.resizeActivity.endDate, PlannedActivities.mouseDownActivityStart, PlannedActivities.mouseDownActivityEnd, true, null);
                    Planboard.changeActivityGroup(Planboard.resizeActivity.id); // send to WebApi
                    const resizedActivity = Planboard.resizeActivity;
                    const actType = Planboard.activityTypes.getObject(resizedActivity.activityTypeId) as ActivityType;
                    if (actType.categoryId === ActivityType.daymarkCategoryId) {
                        Planboard.daymarkStartTime = resizedActivity.startDate;
                        Planboard.daymarkDurationMinutes =
                        (TimeSpan.fromDate(resizedActivity.endDate).totalMiliseconds -
                                TimeSpan.fromDate(resizedActivity.startDate).totalMiliseconds) / 1000 / 60;
                        Planboard.daymarkActivityTypeId = actType.id;
                    }
                    ActivityInfo.refresh();
                }
                Planboard.resizeActivity = null;
                redraw = true;
            }

            // still visible? released while above scrollbar or splitter
            if (Planboard.dragDropControl.visible) {
                Planboard.dragDropControl.visible = false;
                redraw = true;
            }

            Planboard.mouseDownDate = null;

            if (redraw) {
                Planboard.areaMain.redrawAll();
                Planboard.areaToPlan.redrawAll();
                Planboard.controller.redraw();
            }

            Planboard.onMouseUpCell(t, col, row, celX, celY, button, mouseX, mouseY);

            // reset to default
            PlannedActivities.mouseDownActivityStart = null;
            PlannedActivities.mouseDownActivityEnd = null;
        }
    }

    /**
        * remember position and date where the mouse was down
        */
    public static setMouseDownDate(colWidth: number, col: number, row: number, celX: number, mouseX: number, mouseY: number) {
        const minutes = Drawing.screenToMinutes(Planboard.dayStartHour, Planboard.dayEndHour, colWidth, celX);
        Planboard.mouseDownDate = TimeSpan.fromDate(Planboard.leftDate).addDays(col).addMinutes(minutes).toDate();
        Planboard.lastMouseDownPos = [mouseX, mouseY];
        Planboard.selectedActivityCol = col;
        Planboard.selectedActivityRow = row;
        this.mouseDownLineNr = 0;
        this.lastActivityList = null;
    }

    /**
        * add or toggle the multi select state of an activity
        * @param previousSelectedActivity the activity that was selected before Planboard.selectedActivity
        */
    public static addOrToggleMultiSelect(previousSelectedActivity: Activity) {
        if (!Planboard.multiSelectedActivities)
            Planboard.multiSelectedActivities = new Dictionary();

        if (Planboard.multiSelectedActivities.containsKey(Planboard.selectedActivity.id)) {
            // toggle off
            Planboard.multiSelectedActivities.remove(Planboard.selectedActivity.id);
            Planboard.selectedActivity = null;
            Planboard.mouseDownDate = null; // to avoid the click-in new activity
        } else {
            // add
            Planboard.multiSelectedActivities.add(Planboard.selectedActivity.id, Planboard.selectedActivity.id);
            if (previousSelectedActivity && previousSelectedActivity.id !== Planboard.selectedActivity.id) {
                // also remember the activity that was previously selected, this happens for the first item added to multiSelectedActivities
                Planboard.multiSelectedActivities.add(previousSelectedActivity.id, previousSelectedActivity.id);
            }
        }
    }

    /**
        * clear the multiSelectedActivities list if no ctrl or shift key was used and the left mouse button (===0) was pressed
        */
    public static mouseActionClearMultiselect(button: number): boolean {
        const ctrlOrShift = (Planboard.controller.lastMouseDownEvent.ctrlKey || Planboard.controller.lastMouseDownEvent.shiftKey);
        if (!ctrlOrShift && Planboard.multiSelectedActivities != null && button === 0) {
            Planboard.multiSelectedActivities = null;
            return true;
        }
        return false;
    }

    /**
        * determines if an activity will be added to the multi select list
        */
    public static mouseActionToggleMultiselect(button: number, prevSelected: Activity): boolean {
        const ctrlOrShift = (Planboard.controller.lastMouseDownEvent.ctrlKey || Planboard.controller.lastMouseDownEvent.shiftKey);
        if (ctrlOrShift && button === 0) {
            // add the Id to the list of multiSelectedActivities if the left button was pressed
            PlannedActivities.addOrToggleMultiSelect(prevSelected);
            return true;
        }
        else if (!ctrlOrShift && button !== 0 &&
            Planboard.multiSelectedActivities != null &&
            !Planboard.multiSelectedActivities.containsKey(Planboard.selectedActivity.id)) {
            // any other mouse button than the left button on an activity that was not selected will clear the multi select
            Planboard.multiSelectedActivities = null;
            return true;
        }
        return false;
    }

    /**
        * initialize the mouseDownCell event
        */
    private static initMouseDownCell() {
        Planboard.areaMain.mouseDownCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            const colWidth = t.cols.getSize(col);
            let prevSelected = Planboard.selectedActivity;
            Planboard.selectedActivity = null;
            Planboard.resizeActivity = null;

            let multiSelectChanged = PlannedActivities.mouseActionClearMultiselect(button);

            this.setMouseDownDate(colWidth, col, row, celX, mouseX, mouseY);
            const lineNr = Math.floor(celY / Globals.fontHeight);
            this.mouseDownLineNr = lineNr;

            let activities = Planboard.activities.getActivityList(Planboard.activities.getResourceId(row), Planboard.leftDate, col, true);
            if (activities != undefined && activities.items.length > 0) {
                const dayNr = TimeSpan.getDayNr(Planboard.leftDate) + col;
                const rowHeight = t.rows.getSize(row);
                const cornerSize = (Globals.fontHeight >> 1) + 1;

                // enlarge row corner click?
                if (celX >= colWidth - cornerSize && celY >= rowHeight - cornerSize && (colWidth - celX) + (rowHeight - celY) <= cornerSize) {
                    if (this.enlargeRow(t, col, row, activities, prevSelected)) {
                        Planboard.lastMouseDownPos = null; // so there will not be a new activity planned in mouseUp
                        return;
                    }
                }

                // activity clicked?
                for (let actNr = 0; actNr < activities.items.length; actNr++)
                    if (activities.items[actNr].lineNr === lineNr) {
                        let startX = Drawing.dateTimeToX(activities.items[actNr].startDate, dayNr, colWidth, false);
                        let endX = Drawing.dateTimeToX(activities.items[actNr].endDate, dayNr, colWidth, true);
                        if (celX >= startX && celX < endX) {
                            Planboard.selectedActivity = activities.items[actNr];
                            Planboard.selectedActivityCol = col;
                            Planboard.selectedActivityRow = row;
                            Planboard.selectedActivityPos = [celX - startX, celY - (lineNr * Globals.fontHeight + 1)];
                            Planboard.dragDropControl.position.width = 4 + Math.min(Math.max(endX - startX, 4), colWidth);
                            Planboard.dragDropControl.position.height = 4 + Globals.fontHeight - 2;
                            if (Planboard.resizeActivity !== Planboard.selectedActivity)
                                Planboard.resizeActivity = null;
                            if (PlannedActivities.mouseActionToggleMultiselect(button, prevSelected))
                                multiSelectChanged = true;
                        }
                        if (colWidth >= 128 && Planboard.resizeActivity == null) {
                            if ((celX >= startX - 2 && celX < startX + 4) || (celX >= endX - 4 && celX < endX + 2)) {
                                Planboard.resizeActivity = activities.items[actNr];
                                Planboard.selectedActivityCol = col;
                                Planboard.selectedActivityRow = row;
                                Planboard.selectedActivityPos = [celX - startX, celY - (lineNr * Globals.fontHeight + 1)];
                            }
                        }
                    }
            }

            if (col < 0 || row < 0) Planboard.lastMouseDownPos = null; // mousedown was not in a cell

            const mouseDownActivity = Planboard.resizeActivity != null ? Planboard.resizeActivity : Planboard.selectedActivity;
            if (mouseDownActivity != null) {
                PlannedActivities.mouseDownActivityStart = mouseDownActivity.startDate;
                PlannedActivities.mouseDownActivityEnd = mouseDownActivity.endDate;
            }

            if (prevSelected !== Planboard.selectedActivity || multiSelectChanged) {
                Planboard.areaMain.redrawAll();
                Planboard.areaToPlan.redrawAll();
                Planboard.controller.redraw();
            }

            Planboard.onMouseDownCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
    }

    /**
        * perform a resize (change of start- or end time) or a drag drop of the selected activity
        * @param t the grid where the mousedown action took place
        * @param col column in the grid
        * @param celX x position in the cell
        * @param mouseX x position of the mouse cursor on the control
        * @param mouseY y position of the mouse cursor on the control
        * @param reInsert if the activity needs to be reinserted after changing times
        */
    public static mouseMoveResizeActivity(t: AreaControl, col: number, celX: number, mouseX: number, mouseY: number, reInsert: boolean) {
        if (Planboard.readOnly) return;

        // planning a new activity by dragging?
        if (Planboard.selectedActivity == null && Planboard.resizeActivity == null && Planboard.mouseDownDate != null &&
            Planboard.selectedActivityTypeId > 0 && Planboard.lastMouseDownPos != null && Math.abs(Planboard.lastMouseDownPos[0] - mouseX) > 1) {
            // planning a new activity (nothing selected or resizing and a valid activityTypeId is selected, and the mouse has moved)
            this.newActivityId--;
            const newResourceId = t.name === Planboard.areaMain.name ? Planboard.activities.getResourceId(Planboard.selectedActivityRow) : null;
            const roundedDate = this.roundDateMinutes(Planboard.mouseDownDate, this.roundMinutes);
            const newActivity = new Activity(this.newActivityId,
                Planboard.adjustToUtc(roundedDate), Planboard.adjustToUtc(roundedDate),
                Planboard.selectedActivityTypeId, newResourceId, null, Constants.StatusActivityPlanned);
            newActivity.filled = 0;
            newActivity.lineNr = this.mouseDownLineNr;
            Planboard.activities.addActivity(newActivity, false);
            Planboard.selectedActivity = Planboard.resizeActivity = newActivity;
            Planboard.selectedActivityPos = [mouseX < Planboard.lastMouseDownPos[0] ? 0 : 5, Globals.fontHeight / 2];
        }

        // resizing an activity or dragging an activity to a different resource
        if (Planboard.resizeActivity != null &&
            !Planboard.isAnyActivityFromGroupLocked(Planboard.resizeActivity.id)) {
            ActivityInfo.hide();
            t.controller.mouseCursor = "w-resize";
            const colWidth = t.cols.getSize(col);
            if (this.lastActivityList == null)
                this.lastActivityList = t.name === Planboard.areaMain.name
                    ? Planboard.activities.getActivityList(Planboard.activities.getResourceId(Planboard.selectedActivityRow), Planboard.leftDate, col, true)
                    : OpenActivities.getActivitiesInCell(col, Planboard.selectedActivityRow);
            let minutes = Drawing.screenToMinutes(Planboard.dayStartHour, Planboard.dayEndHour, colWidth, celX);
            if (this.roundMinutes > 0)
                minutes = (Math.floor(minutes / this.roundMinutes) * this.roundMinutes) +
                    (minutes % this.roundMinutes > (this.roundMinutes / 2) ? this.roundMinutes : 0);
            let colDate = TimeSpan.fromDate(Planboard.leftDate).addDays(col).addMinutes(minutes).toDate();
            if (Planboard.resizeActivity.id < 0) reInsert = true; // when dragging in a new activity in openActivities, it is okay to reInsert and change day
            if (Planboard.selectedActivityPos[0] <= 4) { // start time change
                if (colDate < Planboard.resizeActivity.endDate) {
                    if (!reInsert && !TimeSpan.sameDayNr(colDate, Planboard.resizeActivity.startDate)) {
                        // not possible to change day if not reInserting
                        minutes = Drawing.screenToMinutes(Planboard.dayStartHour, Planboard.dayEndHour, colWidth,
                            colDate < Planboard.resizeActivity.startDate ? 0 : colWidth);
                        colDate = TimeSpan.fromDateNoTime(Planboard.resizeActivity.startDate).addMinutes(minutes).toDate();
                    }
                    if (reInsert) Planboard.activities.removeActivity(Planboard.resizeActivity); // first remove the activity from memory
                    Planboard.resizeActivity.startDate = colDate;
                    this.forEachOverlappingActivity(this.lastActivityList, Planboard.resizeActivity, null, true,
                        (overlappingActivity) => {
                            Planboard.resizeActivity.startDate = new Date(overlappingActivity.endDate.getTime());
                        });
                    if (reInsert) Planboard.activities.addActivity(Planboard.resizeActivity, false); // re-insert the activity in memory
                    t.redrawCells(col - 20, Planboard.selectedActivityRow, col + 20, Planboard.selectedActivityRow);
                }
            } else { // end time change
                if (colDate > Planboard.resizeActivity.startDate) {
                    if (!reInsert && !TimeSpan.sameDayNr(colDate, Planboard.resizeActivity.endDate)) {
                        // not possible to change day if not reInserting
                        minutes = Drawing.screenToMinutes(Planboard.dayStartHour, Planboard.dayEndHour, colWidth,
                            colDate < Planboard.resizeActivity.endDate ? 0 : colWidth);
                        colDate = TimeSpan.fromDateNoTime(Planboard.resizeActivity.endDate).addMinutes(minutes).toDate();
                    }
                    if (reInsert) Planboard.activities.removeActivity(Planboard.resizeActivity); // first remove the activity from memory
                    Planboard.resizeActivity.endDate = colDate;
                    this.forEachOverlappingActivity(this.lastActivityList, Planboard.resizeActivity, null, true,
                        (overlappingActivity) => {
                            Planboard.resizeActivity.endDate = new Date(overlappingActivity.startDate.getTime());
                        });
                    if (reInsert) Planboard.activities.addActivity(Planboard.resizeActivity, false); // re-insert the activity in memory
                    t.redrawCells(col - 20, Planboard.selectedActivityRow, col + 20, Planboard.selectedActivityRow);
                }
            }
        } else if (Planboard.selectedActivity != null && Planboard.lastMouseDownPos != null &&
            Planboard.selectedActivity.status !== Constants.StatusActivityLocked && Planboard.selectedActivity.origin != Constants.originSystemOWS) {
            Planboard.controller.moveControl(Planboard.dragDropControl,
                new Rect(Math.floor(t.screenPos.left + mouseX - Planboard.selectedActivityPos[0]),
                    Math.floor(t.screenPos.top + mouseY - Planboard.selectedActivityPos[1]),
                    Planboard.dragDropControl.position.width, Planboard.dragDropControl.position.height));
            if (!Planboard.controller.dragDrop) {
                ActivityInfo.hide();
                Planboard.controller.dragDrop = true;
                Planboard.dragDropControl.visible = true;
                t.redrawAll();
                Planboard.controller.redraw();
            }
        }
    }

    /**
        * move the indicator dots to new positions and constrain them by the areaMain (planned activities) position
        */
    public static moveIndicators(t: BasicControl, mouseX: number, mouseY: number) {
        const hPos = Planboard.horizontalIndicator.position;
        const vPos = Planboard.verticalIndicator.position;
        const vPosCounters = Planboard.verticalIndicatorCounters.position;
        const areaMainPos = Planboard.areaMain.screenPos;
        const areaResourcesPos = Planboard.areaResources.screenPos;
        const areaCountersPos = Planboard.areaCounters.screenPos;
        const halfWidth = hPos.width * 0.5;
        const halfHeight = vPos.height * 0.5;
        let newX = t == null || mouseX == null ? hPos.left : t.screenPos.left + mouseX - halfWidth;
        newX = Math.floor(Math.min(Math.max(newX, areaMainPos.left - halfWidth), areaMainPos.right - halfWidth - Globals.scrollBarSize));
        let newY = t == null || mouseY == null ? vPos.top : t.screenPos.top + mouseY - halfHeight;
        newY = Math.floor(Math.min(Math.max(newY, areaMainPos.top - halfHeight), areaMainPos.bottom - halfHeight - Globals.scrollBarSize));
        if (newX !== hPos.left || areaMainPos.top - hPos.height !== hPos.top)
            Planboard.controller.moveControl(Planboard.horizontalIndicator, new Rect(newX, areaMainPos.top - hPos.height, hPos.width, hPos.height));
        if (newY !== vPos.top || areaResourcesPos.right - vPos.width !== vPos.left)
            Planboard.controller.moveControl(Planboard.verticalIndicator, new Rect(areaResourcesPos.right - vPos.width, newY, vPos.width, vPos.height));
        const countersVisible = Planboard.areaCounters.visible && Planboard.split1.getHorizontalSize(2) > 0;
        if (newY !== vPosCounters.top || areaCountersPos.left !== vPosCounters.left || Planboard.verticalIndicatorCounters.visible !== countersVisible) {
            Planboard.verticalIndicatorCounters.visible = countersVisible;
            Planboard.controller.moveControl(Planboard.verticalIndicatorCounters, new Rect(areaCountersPos.left, newY, vPos.width, vPos.height));
        }
    }

    private static initMouseMoveCell() {
        Planboard.areaMain.mouseMoveCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            PlannedActivities.moveIndicators(t, mouseX, mouseY);
            if (button === 0) {
                this.mouseMoveResizeActivity(t, col, celX, mouseX, mouseY, true);
            } else if (button < 0) {
                let mouseoverActivity: Activity = null;
                const activities = Planboard.activities.getActivityList(Planboard.activities.getResourceId(row), Planboard.leftDate, col, true);
                const lineNr = Math.floor(celY / Globals.fontHeight);
                const dayNr = TimeSpan.getDayNr(Planboard.leftDate) + col;
                const colWidth = t.cols.getSize(col);
                if (activities != undefined)
                    for (let actNr = 0; actNr < activities.items.length; actNr++)
                        if (activities.items[actNr].lineNr === lineNr) {
                            let readOnly = Planboard.readOnly;
                            if (Planboard.isAnyActivityFromGroupLocked(activities.items[actNr].id)) readOnly = true;
                            let startX = Drawing.dateTimeToX(activities.items[actNr].startDate, dayNr, colWidth, false);
                            let endX = Drawing.dateTimeToX(activities.items[actNr].endDate, dayNr, colWidth, true);
                            if (celX >= startX && celX < endX)
                                mouseoverActivity = activities.items[actNr];
                            if (colWidth >= 128 && !readOnly)
                                if ((celX >= startX - 2 && celX < startX + 4) || (celX >= endX - 4 && celX < endX + 2))
                                    t.controller.mouseCursor = "w-resize";
                        }
                if (mouseoverActivity == null)
                    ActivityInfo.hide();
                else
                    ActivityInfo.show(Planboard.toolTipControl, mouseoverActivity.id,
                        t.screenPos.left + mouseX, t.screenPos.top + mouseY,
                        Planboard.toolTipControl.visible ? 0 : 500);
            }
        }
    }

    /**
        * Helper method to change an activities resource
        */
    private static changeActivityResource(act: Activity, resourceId: number) {
        Planboard.activities.removeActivity(act); // remove from memory
        act.resourceId = resourceId; // change resource
        if (resourceId == null) {
            act.resourceTypeId = null; // reset resourceTypeId client side when no resource is planned on the activity
        }
        Planboard.activities.addActivity(act, false); // re-insert
        Planboard.activities.checkFilled(act.id);
    }

    /**
        * Helper method to redraw both planning grids and optionally enlarge a row
        */
    private static redrawPlanning(enlargeRow: boolean, t: AreaControl, col: number, row: number) {
        Planboard.areaMain.redrawAll();
        Planboard.areaToPlan.redrawAll();
        if (enlargeRow) this.enlargeRow(t, col, row);
        Planboard.controller.redraw();
    }

    /**
        * Initialize the dragDropCell event (callback).
        * The dragDropCell event is called when the mouse is released in a cell, the parameters refer to the place where something is dropped.
        */
    private static initDragDropCell() {
        Planboard.areaMain.dragDropCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            //console.log(`dragDropCell ${t.name}, (col=${col} , row=${row}), (x=${celX} , y=${celY}), button=${button}, (x=${mouseX} , y=${mouseY})`);
            Planboard.dragDropControl.visible = false;
            Planboard.lastMouseDownPos = null;
            if (Planboard.readOnly) return;
            let newResourceId = null;
            let enlargeRow = false;
            let swapWithActivity: Activity = null;
            let daysChanged = col - Planboard.selectedActivityCol;
            if (t.name === Planboard.areaMain.name) {
                newResourceId = Planboard.activities.getResourceId(row);
                if (newResourceId < 0) {
                    // dropped on whitespace in planboard, just redraw the planboard so the dragged activity will appear again
                    Planboard.redrawAll();
                    return;
                }
                enlargeRow = true;

                // test if there is already an activity in this cel and if it overlaps, then set swapWithActivity to the overlapping activity
                // Planboard.swapActivitiesOnlySameDay: only do this test if the activity was drag dropped in the same column (same day) from a different resource
                if (Planboard.selectedActivity != null &&
                    Planboard.selectedActivity.status !== Constants.StatusActivityLocked &&
                    (daysChanged === 0 || !Planboard.swapActivitiesOnlySameDay) &&
                    (daysChanged !== 0 || newResourceId !== Planboard.selectedActivity.resourceId)) {
                    const activities = Planboard.activities.getActivityList(newResourceId, Planboard.leftDate, col, true);
                    if (activities != null && activities.items.length > 0) {
                        const lineNr = Math.floor(celY / Globals.fontHeight);
                        const dayNr = TimeSpan.getDayNr(Planboard.leftDate) + col;
                        const colWidth = t.cols.getSize(col);
                        // first test if the mouse cursor overlaps with an activity
                        for (let actNr = 0; actNr < activities.items.length; actNr++)
                            if (activities.items[actNr].lineNr === lineNr) {
                                let startX = Drawing.dateTimeToX(activities.items[actNr].startDate, dayNr, colWidth, false);
                                let endX = Drawing.dateTimeToX(activities.items[actNr].endDate, dayNr, colWidth, true);
                                if (celX >= startX && celX < endX)
                                    swapWithActivity = activities.items[actNr];
                            }
                        if (swapWithActivity == null) // now test if activity times overlap
                            for (let actNr = 0; actNr < activities.items.length; actNr++)
                                if (activities.items[actNr].lineNr === lineNr &&
                                    Planboard.selectedActivity.endDate > activities.items[actNr].startDate &&
                                    Planboard.selectedActivity.startDate < activities.items[actNr].endDate)
                                    swapWithActivity = activities.items[actNr];
                        // swapWithActivity may not be a day mark
                        if (swapWithActivity) {
                            let actType = Planboard.activityTypes.getObject(swapWithActivity.activityTypeId) as ActivityType;
                            if (actType && actType.categoryId === ActivityType.daymarkCategoryId) swapWithActivity = null;
                            actType = Planboard.activityTypes.getObject(Planboard.selectedActivity.activityTypeId) as ActivityType;
                            if (actType && actType.categoryId === ActivityType.daymarkCategoryId) swapWithActivity = null;
                        }
                    }
                }
            }
            if (t.name === Planboard.areaMain.name || t.name === Planboard.areaToPlan.name) {
                if (Planboard.selectedActivity != null &&
                    Planboard.selectedActivity.status !== Constants.StatusActivityLocked &&
                    (newResourceId !== Planboard.selectedActivity.resourceId || daysChanged !== 0)) {
                    if (daysChanged !== 0 && swapWithActivity == null && !Planboard.isAnyActivityFromGroupLocked(Planboard.selectedActivity.id)) {
                        // move to other date, must change the whole group
                        const moveActivitySelectedActivity = Planboard.selectedActivity;
                        const prevEnlargeRow = enlargeRow;
                        enlargeRow = false;
                        Planboard.showYesNoMessage(
                            Planboard.getTextLabel("MOVE_ACTIVITY_TITLE"),
                            Planboard.getTextLabel("MOVE_ACTIVITY_QUESTION"),
                            () => { // yes
                                const actType = Planboard.activityTypes.getObject(moveActivitySelectedActivity.activityTypeId) as ActivityType;
                                if (newResourceId == null && actType && actType.categoryId === ActivityType.daymarkCategoryId) {
                                    // if an activity is moved to no resource and the activity is a day mark, then it should be removed
                                    Planboard.activities.removeActivity(moveActivitySelectedActivity); // first remove the activity from memory
                                    moveActivitySelectedActivity.resourceId = newResourceId; // change resource
                                    Planboard.deleteActivity(moveActivitySelectedActivity); // send to WebApi
                                } else {
                                    // move the activity group to another day
                                    Planboard.moveActivityGroup(moveActivitySelectedActivity.id, daysChanged, null, null, null, null, false,
                                        (group: Activity[]) => {
                                            moveActivitySelectedActivity.resourceId = newResourceId; // change resource of selected activity
                                        });
                                    Planboard.changeActivityGroup(moveActivitySelectedActivity.id); // send to WebApi
                                }
                                ActivityInfo.refresh();
                                PlannedActivities.redrawPlanning(prevEnlargeRow, t, col, row);
                            },
                            () => { });  // no, do nothing
                    } else {
                        // only the resource has changed, only change this one activity
                        if (newResourceId == null) {
                            // if an activity is moved to no resource and the activity is a day mark, then it should be removed
                            const actType = Planboard.activityTypes.getObject(Planboard.selectedActivity.activityTypeId) as ActivityType;
                            if (actType && actType.categoryId === ActivityType.daymarkCategoryId) {
                                const dayMarkGroup = Planboard.getAllActivitiesInGroup(Planboard.selectedActivity.id);
                                Planboard.activities.removeActivity(Planboard.selectedActivity); // first remove the activity from memory
                                Planboard.selectedActivity.resourceId = newResourceId; // change resource

                                const parentDayMark = dayMarkGroup.length <= 2 ?
                                    dayMarkGroup.find(dm => dm.id === Planboard.selectedActivity.parentId) : undefined;
                                if (parentDayMark) { // last remaining leaf, so delete parent (deletes all remaining leaves)
                                    Planboard.activities.removeActivity(parentDayMark);
                                    Planboard.deleteActivity(parentDayMark); // send to WebApi
                                } else { // other sibbling leaves remaining, so delete only selected
                                    Planboard.deleteActivity(Planboard.selectedActivity); // send to WebApi
                                }
                                Planboard.selectedActivity = null; // set to null so it will not be reinserted
                            }
                        }
                        if (Planboard.selectedActivity != null) {
                            if (swapWithActivity != null) {
                                const prevEnlargeRow = enlargeRow;
                                enlargeRow = false;
                                Planboard.showQuestionMessage(
                                    Planboard.getTextLabel("SWAP_ACTIVITY_TITLE"),
                                    Planboard.getTextLabel("SWAP_ACTIVITY_QUESTION"),
                                    [
                                        Planboard.getTextLabel("SWAP_ACTIVITY"),
                                        Planboard.getTextLabel("MOVE_ACTIVITY"),
                                        Planboard.getTextLabel("CANCEL")
                                    ],
                                    [
                                        () => { // swap action, swap the resources
                                            PlannedActivities.changeActivityResource(swapWithActivity, Planboard.selectedActivity.resourceId);
                                            PlannedActivities.changeActivityResource(Planboard.selectedActivity, newResourceId);
                                            if (daysChanged !== 0) {
                                                Planboard.moveActivityGroup(swapWithActivity.id, -daysChanged, null, null, null, null, false, (group: Activity[]) => { });
                                                Planboard.moveActivityGroup(Planboard.selectedActivity.id, daysChanged, null, null, null, null, false, (group: Activity[]) => { });
                                            }
                                            Planboard.swapActivities(Planboard.selectedActivity.id, swapWithActivity.id); // send to WebApi
                                            ActivityInfo.refresh();
                                            PlannedActivities.redrawPlanning(false, null, 0, 0);
                                        },
                                        () => { // move action, just change the resource
                                            if (daysChanged !== 0) { // move to other date, must change the whole group
                                                Planboard.moveActivityGroup(Planboard.selectedActivity.id, daysChanged, null, null, null, null, false,
                                                    (group: Activity[]) => {
                                                        Planboard.selectedActivity.resourceId = newResourceId; // change resource of selected activity
                                                    });
                                                Planboard.changeActivityGroup(Planboard.selectedActivity.id); // send to WebApi
                                            } else {
                                                PlannedActivities.changeActivityResource(Planboard.selectedActivity, newResourceId);
                                                Planboard.changeActivity(Planboard.selectedActivity.id); // send to WebApi
                                            }
                                            ActivityInfo.refresh();
                                            PlannedActivities.redrawPlanning(prevEnlargeRow, t, col, row);
                                        },
                                        () => { } // cancel action, do not do anything
                                    ]);
                            } else {
                                PlannedActivities.changeActivityResource(Planboard.selectedActivity, newResourceId);
                                Planboard.changeActivity(Planboard.selectedActivity.id); // send to WebApi
                            }
                        }
                    }
                    if (Planboard.selectedActivity)
                        Planboard.activities.checkFilled(Planboard.selectedActivity.id);
                    ActivityInfo.refresh();
                }
            }
            PlannedActivities.redrawPlanning(enlargeRow, t, col, row);
        }

        Planboard.areaMain.dragDropCancel = () => {
            Planboard.dragDropControl.visible = false;
            Planboard.areaMain.redrawAll();
            Planboard.areaToPlan.redrawAll();
            Planboard.controller.redraw();
        }
    }

    private static initMouseLeave() {
        Planboard.areaMain.mouseLeave = Planboard.areaMain.wheelScrollingStart = () => {
            ActivityInfo.hide();
        }
    }
}